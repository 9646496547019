import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import styled, { css, keyframes } from "styled-components";
import axios from "axios";
import ImageSelector from "../components/ImageSelector";
import Textinput from "../components/Textinput";
import Sourceinput from "../components/Sourceinput";
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import DateInput from "../components/Dateinput";
import { useAppDispatch } from "../store";
import userSlice from "../slice/user";
import ogs from "open-graph-scraper"; // Import the open-graph-scraper library

export default function Post_Write() {
  const location = useLocation();
  const existTitle = location.state?.title;
  const existContent = location.state?.content;
  const existMainimg =
    location.state?.mainimg &&
    "https://media.dplanit.co.kr/" + location.state?.mainimg;
  const existThumbimg =
    location.state?.thumbimg &&
    "https://media.dplanit.co.kr/" + location.state?.thumbimg;
  const existType = location.state?.type;
  const writetype = location.state?.writeType;
  const existDate = location.state?.expireDate;
  const existPosition = location.state?.position;
  const existOrder = location.state?.order;
  const postno = location.state?.postno;
  const writerid = location.state?.writerid;
  const existsource = location.state?.source;
  const link = location.state?.link;
  const existSummary = location.state?.summary;
  const isEdit = location.state?.isEdit;
  const storylink = location.state?.contentLink;
  const [category, setCategory] = useState([]);
  const [categoryNo, setCategoryNo] = useState([]);
  const [catergoryIndex, setCategoryIndex] = useState(existType || 0);
  const [title, setTitle] = useState(existTitle || "");
  // const [link, setLink] = useState(existTitle || "");
  const [source, setSource] = useState(existsource || "");
  const [summary, setSummary] = useState(existSummary || "");
  const [sourcelink, setSourcelink] = useState(link || "");
  const [content, setContent] = useState(existContent || "");
  const [WriterList, setWriterList] = useState([]);
  const [selectedWriter, setSelectedWriter] = useState(writerid || null);
  const [position, setPosition] = useState([
    { positionName: "홈 상단 베너", positionNo: 0 },
    { positionName: "홈 중간 베너", positionNo: 1 },
    { positionName: "홈 모달 팝업", positionNo: 2 },
    { positionName: "포스트 하단 배너", positionNo: 3 },
  ]);
  const [positionIndex, setPositionIndex] = useState(existPosition || 0);
  const [image, setImage] = useState(existMainimg || null);

  const [thumbimg, setThumbimg] = useState(existThumbimg || null);
  const [thumbData, setThumbData] = useState(existThumbimg || null);
  const [imageData, setImageData] = useState(existMainimg || null);
  const [order, setOrder] = useState(existOrder || null);
  const [loading, setLoading] = useState(false);
  const [writeType, setWriteType] = useState([
    { value: 0, label: "공지형" },
    { value: 1, label: "댓글형" },
  ]);
  const [push, setPush] = useState(false);
  const [selectedWriteType, setSelectedWriteType] = useState(existType || 0);
  const [contentLink, setContentLink] = useState(storylink || "");
  const [date, setDate] = useState(existDate || "");
  const dispatch = useAppDispatch();
  const editorRef = useRef(null);
  const navigation = useNavigate();
  console.log("thumbimg : ", thumbimg);
  useEffect(() => {
    Cate();
    getWriterList();
  }, [loading]);

  const handleSelect = (e) => {
    setSelectedWriter(e.target.value);
  };
  const handleCheckboxChange = (event) => {
    // 이벤트에서 체크박스의 체크 상태를 가져옵니다.
    const isChecked = event.target.checked;
    if (isChecked) {
      swal("정말 모든 유저에게 알림을 보내겠습니까?", { icon: "warning" });
    }
    // 가져온 체크 상태를 사용하여 push 변수를 업데이트합니다.
    setPush(isChecked);
  };
  const getWriterList = async () => {
    try {
      await axios
        .get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/story/writer`,
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          const fetchedWriters = res.data.data.map((writer) => ({
            value: writer.id, // 작가 데이터에 적절한 프로퍼티로 변경해 주세요.
            label: writer.name, // 작가 데이터에 적절한 프로퍼티로 변경해 주세요.
          }));
          fetchedWriters.unshift({
            value: null,
            label: "-- Select an option --",
          });
          setWriterList(fetchedWriters);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      if (response.data.popdata.poptext) {
        swal(response.data.popdata.poptext, { icon: "error" });
        return;
      }
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/story/writer`,
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          const fetchedWriters = res.data.data.map((writer) => ({
            value: writer.id, // 작가 데이터에 적절한 프로퍼티로 변경해 주세요.
            label: writer.name, // 작가 데이터에 적절한 프로퍼티로 변경해 주세요.
          }));

          setWriterList(fetchedWriters);
        });
    }
  };

  const handlePaste = async (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");

    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

    if (urlRegex.test(pastedData)) {
      event.preventDefault();

      try {
        setLoading(true);
        const ogData = await makeNewLink(pastedData);
        const ogHTML = ogData.data.data.data;

        const updatedContent =
          content.replace(new RegExp(pastedData, "g"), "") + ogHTML;
        editorRef.current.getInstance().setMarkdown(updatedContent);

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch OG data:", error);
      }
    }
  };
  const Cate = async () => {
    try {
      await axios
        .get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/story/category`,
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setCategory(res.data.data);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      if (response.data.popdata.poptext) {
        swal(response.data.popdata.poptext, { icon: "error" });
        return;
      }
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/story/category`,
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setCategory(res.data.data.cateName);
          setCategoryNo(res.data.data.cateNo);
        });
    }
  };
  const makeNewLink = async (url) => {
    const response = await axios.post(
      `${
        process.env.NODE_ENV == "development"
          ? "http://localhost:2005"
          : "https://dplanit.dplanit.co.kr"
      }/admin/post/newlink`,
      { url: url },
      {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
      }
    );
    return response;
  };
  const handleChange = () => {
    // 현재 텍스트 값을 가져옵니다.
    const currentValue = editorRef.current?.getInstance().getHTML();

    // 링크 태그의 정규식 패턴을 설정합니다.
    const linkRegex = /<a href="(.*?)">(.*?)<\/a>/g;

    // 이전 텍스트에서 추출한 링크 태그들을 저장할 배열을 선언합니다.
    let extractedLinks = [];

    // 이전 텍스트에서 링크 태그를 추출하여 배열에 저장합니다.
    content.replace(linkRegex, (match) => {
      extractedLinks.push(match);
      return match;
    });

    // 현재 텍스트에 추출한 링크 태그들을 다시 삽입합니다.
    let updatedValue = currentValue;

    extractedLinks.forEach((link) => {
      updatedValue = updatedValue.replace(link, link);
    });

    setContent(updatedValue);
  };
  console.log("pushpushpushpushpushpushpushpush : ", push);
  console.log("content : ", content);
  const InsertPost = async (content, position, mainimg, order, type) => {
    if (!content) {
      swal("내용을 입력해주세요", { icon: "error" });
    }
    if (!mainimg) {
      swal("메인 이미지를 입력해주세요", { icon: "error" });
    }
    if (!date) {
      swal("만료일을 입력해주세요", { icon: "error" });
    }
    if (!order) {
      swal("노출순서를 입력해주세요", { icon: "error" });
    }
    console.log("content2 : ", content);
    const formData = new FormData();
    formData.append("content", content);
    formData.append("type", type);
    formData.append("mainimg", mainimg);
    formData.append("order", order);
    formData.append("position", position);
    formData.append("date", date);
    formData.append("push", push);
    formData.append("title", title);
    try {
      await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/banner/insert`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/adsence");
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      const response_2 = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/banner/insert`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      if (response_2.data.popdata.poptext) {
        swal(response_2.data.popdata.poptext, {
          icon: "error",
        });
      }
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/adsence");
    }
  };
  // const InsertPost_YOUTUBE = async (
  //   title,
  //   thumpath,
  //   content,
  //   contentLink,
  //   writerid,
  //   source,
  //   sourcelink,
  //   type,
  //   postType
  // ) => {
  //   if (!title) {
  //     swal("제목을 입력해주세요", { icon: "error" });
  //   }
  //   if (!content) {
  //     swal("내용을 입력해주세요", { icon: "error" });
  //   }
  //   if (!contentLink) {
  //     swal("컨텐츠링크를 입력해주세요", { icon: "error" });
  //   }
  //   if (!thumpath) {
  //     swal("썸네일 이미지를 입력해주세요", { icon: "error" });
  //   }
  //   if (!type) {
  //     swal("카테고리가 존재하지 않습니다.", { icon: "error" });
  //   }
  //   if (!postType) {
  //     swal("게시글 타입이 존재하지 않습니다.", { icon: "error" });
  //   }
  //   const formData = new FormData();
  //   formData.append("title", title);
  //   formData.append("content", content);
  //   formData.append("contentLink", contentLink);
  //   formData.append("thumbimg", thumpath);
  //   formData.append("writerid", writerid);
  //   formData.append("source", source);
  //   formData.append("sourcelink", sourcelink);
  //   formData.append("type", type);
  //   formData.append("postType", postType);
  //   try {
  //     await axios.post(
  //       `${
  //         process.env.NODE_ENV == "development"
  //           ? "http://localhost:2005"
  //           : "https://dplanit.dplanit.co.kr"
  //       }/admin/banner/insert`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: localStorage.getItem("accessToken"),
  //         },
  //         withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
  //       }
  //     );
  //     swal("등록되었습니다!", {
  //       icon: "success",
  //     });
  //     navigation("/post");
  //   } catch (error) {
  //     const response = await axios.get(
  //       `${
  //         process.env.NODE_ENV == "development"
  //           ? "http://localhost:2005"
  //           : "https://dplanit.dplanit.co.kr"
  //       }/admin/refresh`,
  //       {
  //         headers: {
  //           Authorization: localStorage.getItem("accessToken"),
  //         },
  //         withCredentials: true,
  //       }
  //     );
  //     const newAct = response.data.data.Authorization;
  //     localStorage.setItem("Authorization", newAct);
  //     const response_2 = await axios.post(
  //       `${
  //         process.env.NODE_ENV == "development"
  //           ? "http://localhost:2005"
  //           : "https://dplanit.dplanit.co.kr"
  //       }/admin/banner/insert`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: localStorage.getItem("accessToken"),
  //         },
  //         withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
  //       }
  //     );
  //     if (response_2.data.popdata.poptext) {
  //       swal(response_2.data.popdata.poptext, {
  //         icon: "error",
  //       });
  //     }
  //     swal("등록되었습니다!", {
  //       icon: "success",
  //     });
  //     navigation("/post");
  //   }
  // };
  // const InsertPost_BLOG = async (
  //   title,
  //   thumpath,
  //   contentLink,
  //   type,
  //   postType
  // ) => {
  //   if (!title) {
  //     swal("제목을 입력해주세요", { icon: "error" });
  //   }
  //   if (!thumpath) {
  //     swal("썸네일 이미지를 입력해주세요", { icon: "error" });
  //   }
  //   if (!contentLink) {
  //     swal("컨텐츠링크를 입력해주세요", { icon: "error" });
  //   }
  //   if (!type) {
  //     swal("카테고리가 존재하지 않습니다.", { icon: "error" });
  //   }
  //   if (!postType) {
  //     swal("게시글 타입이 존재하지 않습니다.", { icon: "error" });
  //   }
  //   const formData = new FormData();
  //   formData.append("title", title);
  //   formData.append("contentLink", contentLink);
  //   formData.append("thumbimg", thumpath);
  //   formData.append("type", type);
  //   formData.append("postType", postType);
  //   try {
  //     await axios.post(
  //       `${
  //         process.env.NODE_ENV == "development"
  //           ? "http://localhost:2005"
  //           : "https://dplanit.dplanit.co.kr"
  //       }/admin/banner/insert`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: localStorage.getItem("accessToken"),
  //         },
  //         withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
  //       }
  //     );
  //     swal("등록되었습니다!", {
  //       icon: "success",
  //     });
  //     navigation("/post");
  //   } catch (error) {
  //     const response = await axios.get(
  //       `${
  //         process.env.NODE_ENV == "development"
  //           ? "http://localhost:2005"
  //           : "https://dplanit.dplanit.co.kr"
  //       }/admin/refresh`,
  //       {
  //         headers: {
  //           Authorization: localStorage.getItem("accessToken"),
  //         },
  //         withCredentials: true,
  //       }
  //     );
  //     const newAct = response.data.data.Authorization;
  //     localStorage.setItem("Authorization", newAct);
  //     dispatch(
  //       userSlice.actions.updateAccessToken({
  //         Authorization: localStorage.getItem("accessToken"),
  //       })
  //     );
  //     const response_2 = await axios.post(
  //       `${
  //         process.env.NODE_ENV == "development"
  //           ? "http://localhost:2005"
  //           : "https://dplanit.dplanit.co.kr"
  //       }/admin/banner/insert`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: localStorage.getItem("accessToken"),
  //         },
  //         withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
  //       }
  //     );
  //     if (response_2.data.popdata.poptext) {
  //       swal(response_2.data.popdata.poptext, {
  //         icon: "error",
  //       });
  //     }
  //     swal("등록되었습니다!", {
  //       icon: "success",
  //     });
  //     navigation("/post");
  //   }
  // };

  const UpdatePost = async (content, position, mainimg, order, type) => {
    if (!content) {
      swal("내용을 입력해주세요", { icon: "error" });
    }
    if (!mainimg) {
      swal("메인 이미지를 입력해주세요", { icon: "error" });
    }
    const formData = new FormData();
    formData.append("content", content);
    formData.append("type", type);
    formData.append("mainimg", mainimg);
    formData.append("bannerno", postno);
    formData.append("order", order);
    formData.append("position", position);
    formData.append("date", date);
    try {
      await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/banner/update`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/adsence");
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      const response_2 = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/banner/update`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      if (response_2.data.popdata.poptext) {
        swal(response_2.data.popdata.poptext, {
          icon: "error",
        });
      }
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    }
  };
  const handleSelectWriteType = (e) => {
    setSelectedWriteType(e.target.value);
  };
  return (
    <Layout
      children={
        <>
          <VarticalContainer>
            <HeaderCon>
              <HorizontalContainer>
                <MainTitle>배너광고 등록</MainTitle>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <text style={{ color: "#888" }}>홈 &nbsp;&nbsp;</text>
                  <img
                    src={process.env.PUBLIC_URL + "/ico_arrow_right_gray.png"}
                    style={{ width: "19px", height: "19px" }}
                  />
                  <text style={{ color: "#888" }}>
                    &nbsp;&nbsp;광고 등록&nbsp;&nbsp;
                  </text>
                  <img
                    src={process.env.PUBLIC_URL + "/ico_arrow_right_gray.png"}
                    style={{ Width: "19px", height: "19px" }}
                  />
                  <text>&nbsp;&nbsp;광고 등록</text>
                </div>
              </HorizontalContainer>
              <div>
                <hr style={{ border: "1px solid #E9EAEE" }} />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "space-between",
                  paddingTop: "10px",
                  paddingBottom: "20px",
                  paddingLeft: "55px",
                  paddingRight: "55px",
                }}
              >
                <div style={{ display: isEdit ? "none" : "flex" }}>
                  {position.map((item, index) => (
                    <Button
                      onClick={() => {
                        setPositionIndex(item.positionNo);
                      }}
                      style={{
                        borderColor:
                          positionIndex === item.positionNo
                            ? "black"
                            : "#C4C4C4",
                        cursor: "pointer",
                      }}
                    >
                      {item.positionName}
                    </Button>
                  ))}
                </div>
                <div>
                  <label>작성타입</label>
                  <Select
                    value={selectedWriteType}
                    onChange={handleSelectWriteType}
                  >
                    {writeType &&
                      writeType.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                  </Select>
                </div>
                <div>
                  <ColorButton
                    style={{
                      background: "#fff",
                      color: "#4674FE",
                      borderColor: "#4674FE",
                    }}
                    onClick={() => {
                      if (isEdit) {
                        UpdatePost(
                          content,
                          positionIndex,
                          imageData,
                          order,
                          selectedWriteType
                        );
                      } else {
                        InsertPost(
                          content,
                          positionIndex,
                          imageData,
                          order,
                          selectedWriteType,
                          push,
                          title
                        );
                      }
                    }}
                  >
                    <text>등록하기</text>
                  </ColorButton>
                </div>
              </div>
            </HeaderCon>

            {/* { 작성타입 : 공지형 } */}
            {selectedWriteType == 0 && (
              <>
                <ThumbCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>썸네일 </text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  {/* <div>
                    <div style={{ marginRight: "40px" }}>
                      <text>리스트 썸네일</text>
                    </div>
                    <div>
                      <ImageSelector
                        image={thumbimg}
                        setImage={setThumbimg}
                        setData={setThumbData}
                        width="288px"
                        height="145px"
                      />
                      <div style={{ marginTop: "20px" }}>
                        <text>(첨부 크기 : 314 x 158)</text>
                      </div>
                    </div>
                  </div> */}

                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "40px" }}>
                      <text>메인 이미지</text>
                    </div>
                    <div>
                      <ImageSelector
                        image={image}
                        setImage={setImage}
                        setData={setImageData}
                        width="133px"
                        height="145px"
                      />
                      <div style={{ marginTop: "20px" }}>
                        <text>(첨부 크기 : 360 x 389)</text>
                      </div>
                    </div>
                  </div>
                </ThumbCon>

                <TitleCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>만료일</text>
                  <div
                    style={{ marginBottom: "40px", marginTop: "30px" }}
                  ></div>
                  <DateInput date={date} setDate={setDate} />
                </TitleCon>
                <TitleCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>
                    이벤트 문구
                  </text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <Textinput text={title} setText={setTitle} />
                </TitleCon>
                
                <SourceCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>
                    노출 순서
                  </text>
                  <div
                    style={{ marginBottom: "40px", marginTop: "30px" }}
                  ></div>

                  <br />
                  <Sourceinput text={order} setText={setOrder} />
                </SourceCon>

                <WriteCon onPaste={handlePaste}>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>내용</text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <Editor
                    initialValue={content}
                    ref={editorRef}
                    onChange={handleChange}
                    placeholder="내용을 입력해주세요."
                    previewStyle="vertical" // 미리보기 스타일 지정
                    height="300px" // 에디터 창 높이
                    initialEditType="wysiwyg" // 초기 입력모드 설정(디폴트 markdown)
                    toolbarItems={[
                      // 툴바 옵션 설정
                      ["heading", "bold", "italic", "strike"],
                      ["hr", "quote"],
                      ["ul", "ol", "task", "indent", "outdent"],
                      ["table", "image", "link"],
                      ["code", "codeblock"],
                    ]}
                  ></Editor>
                  <div>
                    {/* 체크박스 */}
                    <input
                      type="checkbox"
                      checked={push} // 체크 상태를 push 변수와 연결합니다.
                      onChange={handleCheckboxChange} // 변경 이벤트 핸들러 연결
                    />
                    <label>전체알림: {push ? "활성화" : "비활성화"}</label>
                  </div>
                </WriteCon>
              </>
            )}
            {/* { 작성타입 : 댓글형 } */}
            {selectedWriteType == 1 && (
              <>
                <ThumbCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>썸네일</text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "40px" }}>
                      <text>메인 이미지</text>
                    </div>
                    <div>
                      <ImageSelector
                        image={image}
                        setImage={setImage}
                        setData={setImageData}
                        width="133px"
                        height="145px"
                      />
                      <div style={{ marginTop: "20px" }}>
                        <text>(첨부 크기 : 360 x 389)</text>
                      </div>
                    </div>
                  </div>
                  {/* <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "40px" }}>
                      <text>리스트 썸네일</text>
                    </div>
                    <div>
                      <ImageSelector
                        image={thumbimg}
                        setImage={setThumbimg}
                        setData={setThumbData}
                        width="288px"
                        height="145px"
                      />
                      <div style={{ marginTop: "20px" }}>
                        <text>(첨부 크기 : 314 x 158)</text>
                      </div>
                    </div>
                  </div> */}
                </ThumbCon>

                <SourceCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>
                    노출 순서
                  </text>
                  <div
                    style={{ marginBottom: "40px", marginTop: "30px" }}
                  ></div>

                  <br />
                  <Sourceinput text={order} setText={setOrder} />
                </SourceCon>

                <TitleCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>만료일</text>
                  <div
                    style={{ marginBottom: "40px", marginTop: "30px" }}
                  ></div>
                  <DateInput date={date} setDate={setDate} />
                </TitleCon>
                <TitleCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>
                    이벤트 문구
                  </text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <Textinput text={title} setText={setTitle} />
                </TitleCon>

                <WriteCon onPaste={handlePaste}>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>내용</text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <Editor
                    initialValue={content}
                    ref={editorRef}
                    onChange={handleChange}
                    placeholder="내용을 입력해주세요."
                    previewStyle="vertical" // 미리보기 스타일 지정
                    height="300px" // 에디터 창 높이
                    initialEditType="wysiwyg" // 초기 입력모드 설정(디폴트 markdown)
                    toolbarItems={[
                      // 툴바 옵션 설정
                      ["heading", "bold", "italic", "strike"],
                      ["hr", "quote"],
                      ["ul", "ol", "task", "indent", "outdent"],
                      ["table", "image", "link"],
                      ["code", "codeblock"],
                    ]}
                  ></Editor>
                  <div>
                    {/* 체크박스 */}
                    <input
                      type="checkbox"
                      checked={push} // 체크 상태를 push 변수와 연결합니다.
                      onChange={handleCheckboxChange} // 변경 이벤트 핸들러 연결
                    />
                    <label>전체알림: {push ? "활성화" : "비활성화"}</label>
                  </div>
                </WriteCon>
              </>
            )}
            {/* { 작성타입 : 블로그 } */}
            {selectedWriteType == 2 && (
              <>
                <ThumbCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>썸네일</text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "40px" }}>
                      <text>리스트 썸네일</text>
                    </div>
                    <div>
                      <ImageSelector
                        image={thumbimg}
                        setImage={setThumbimg}
                        setData={setThumbData}
                        width="288px"
                        height="145px"
                      />
                      <div style={{ marginTop: "20px" }}>
                        <text>(첨부 크기 : 314 x 158)</text>
                      </div>
                    </div>
                  </div>
                </ThumbCon>

                <TitleCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>제목</text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <Textinput text={title} setText={setTitle} />
                </TitleCon>

                <TitleCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>
                    블로그 링크
                  </text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <Sourceinput text={contentLink} setText={setContentLink} />
                </TitleCon>
              </>
            )}
          </VarticalContainer>
        </>
      }
    ></Layout>
  );
}
const VarticalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 90vh;
`;
const HorizontalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 30px 60px 30px 60px;
`;
const HeaderCon = styled.div`
  flex: 0.1;
  background-color: #fff;
  box-sizing: border-box;
`;
const ThumbCon = styled.div`
  flex: 1;
  display: flex;
  background-color: #fff;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;
const DetailCon = styled.div`
  flex: 0.2;
  background-color: #fff;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;
const TitleCon = styled.div`
  flex: 0.14;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;
const SourceCon = styled.div`
  flex: 0.14;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;
const Writer = styled.div`
  flex: 0.14;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
  display: flex; // 추가
  justify-content: space-between; // 추가
  select {
    font-size: 16px;
    padding: 4px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

const Select = styled.select`
  margin: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #eee; /* 아래쪽 테두리만 적용 */
  padding: 0.5rem;
  height: 34px;
  width: 360px;
  cursor: pointer;
`;

const WriteCon = styled.div`
  flex: 0.36;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;

const ColorButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.0001);
  border: 1px solid #4674fe;
  border-radius: 20px;
  cursor: pointer;
  height: 34px;
  width: 101px;
  border-radius: 20px;
  margin-top: 10px;
`;
const Button = styled.button`
  background: #ffffff;
  border: 2px solid #000000;
  border-radius: 4px;
  width: 174px;
  height: 54px;
  margin-right: 6.76px;
`;

const MainTitle = styled.text`
  font-family: "NanumBarunGothicOTF";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
  display: flex;
  align-items: center;
`;
