import React, { useState } from "react";
import styled from "styled-components";
import AnswerContainer from "./AnswerContainer";
export default function QuestionContainer(props) {
  const [showAnswer, setShowAnswer] = useState();
  const handleShowAnswer = () => {
    setShowAnswer(!showAnswer);
  };
  return (
    <>
      <HorizontalContainer onClick={handleShowAnswer}>
        <QuesTitle>{props.title}</QuesTitle>
        <ItemCntainer>
          <QuesStateText
            style={{
              color:
                props.state === 1
                  ? "#000"
                  : props.state === 2
                  ? "green"
                  : props.state === 3
                  ? "red"
                  : "#4674FE",
            }}
          >
            {props.state === 0
              ? "미답변"
              : props.state === 1
              ? "답변완료"
              : props.state === 2
              ? "답변보류"
              : props.state === 3
              ? "답변거부"
              : ""}
          </QuesStateText>
          <ArrowImg
            src={process.env.PUBLIC_URL + "/ico_arrow_bottom_black.png"}
          />
        </ItemCntainer>
      </HorizontalContainer>
      {showAnswer && (
        <AnswerContainer
          qnano={props.qnano}
          userName={props.userName}
          writeDate={props.writeDate}
          boardtype={props.boardtype}
          text={props.text}
          state={props.state}
          answer={props.answer}
          setLoading={props.setLoading}
          loading={props.loading}
        />
      )}
      <hr style={{ border: "0.5px solid #E6E7EB" }} />
    </>
  );
}

const HorizontalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 30px 0px 30px 0px;
  cursor: pointer;
`;
const ItemCntainer = styled.div`
  display: flex;
  align-items: center;
`;
const ArrowImg = styled.img`
  height: 20px;
  width: 20.972476959228516px;
  left: 1503.02734375px;
  top: 30px;
  border-radius: 0px;
`;
const QuesTitle = styled.text`
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
`;
const QuesStateText = styled.text`
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
  margin-right: 53.8px;
`;
