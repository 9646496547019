import {
  BrowserRouter,
  Routes,
  Route,
  redirect,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import Coaching from "./pages/Coaching";
import Home from "./pages/Home";
import Member from "./pages/Member";
import MemberInfo from "./pages/MemberInfo";
import Notice from "./pages/Notice";
import Post from "./pages/Post";
import Layout from "./components/Layout";
import Post_Write from "./pages/PostWrite";
import CoachingChat from "./pages/Coaching/CoachingChat";
import CoachingManagement from "./pages/Coaching/CoachingManagement";
import GroupManagement from "./pages/Coaching/GroupManagement";
import QuestionManagement from "./pages/Coaching/QuestionManagement";
import NoticeWrite from "./pages/NoticeWrite";
import FaqWrite from "./pages/FaqWrite";
import PayManagement from "./pages/Pay/PayManagement";
import SystemRole from "./pages/System/SystemRole";
import SystemVersion from "./pages/System/SystemVersion";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import userSlice from "./slice/user";
import { useAppDispatch } from "./store";
import axios from "axios";
import Adsence from "./pages/Adsence";
import AdsenceWrite from "./pages/AdsenceWrite";
import AdsenceDetail from "./pages/BannerDetail";
import Faq from "./pages/offenQna";
import Talk from "./pages/Talk";
import DptalkDetail from "./pages/DptalkDetail";
import LocalTalkDetail from "./pages/LocalTalkDetail";
import Talkad from "./pages/TalkAd";
import TalkadWrite from "./pages/TalkAdWrite";

function App() {
  const dispatch = useAppDispatch();
  const username = localStorage.getItem("username"); // 로컬 스토리지에서 토큰 가져오기
  const accessToken = localStorage.getItem("accessToken"); // 로컬 스토리지에서 토큰 가져오기
  dispatch(
    userSlice.actions.setUser({
      name: username,
      accesstoken: accessToken,
    })
  );
  const isLoggedIn = useSelector((state) => state.user.accesstoken);

  const checkAccessToken = async () => {
    try {
      const res = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://192.168.0.36:2500"
            : "https://dplanit.dplanit.co.kr"
        }/admin/logintoken`,

        {
          headers: {
            Authorization: isLoggedIn,
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    //checkAccessToken();
  }, [isLoggedIn]);
  return (
    <BrowserRouter>
      <Routes>
        {isLoggedIn ? (
          <>
            <Route path="coaching" element={<QuestionManagement />} />
            <Route path="home" element={<Home />} />
            <Route path="member" element={<Member />} />
            <Route path="member/info" element={<MemberInfo />} />
            <Route path="notice" element={<Notice />} />
            <Route path="notice/write" element={<NoticeWrite />} />
            <Route path="adsence" element={<Adsence />} />
            <Route path="adsence/write" element={<AdsenceWrite />} />
            <Route path="adsence/detail" element={<AdsenceDetail />} />
            <Route path="talkad" element={<Talkad />} />
            <Route path="talkad/write" element={<TalkadWrite />} />
            <Route path="faq" element={<Faq />} />
            <Route path="faq/write" element={<FaqWrite />} />
            <Route path="talk" element={<Talk />} />
            <Route path="talk/detail" element={<DptalkDetail />} />
            <Route path="talk/local/detail" element={<LocalTalkDetail />} />
            <Route path="pay/management" element={<PayManagement />} />
            <Route path="post" element={<Post />} />
            <Route path="post/write" element={<Post_Write />} />
            <Route path="system" element={<SystemRole />} />
            <Route path="system/role" element={<SystemRole />} />
            <Route path="system/version" element={<SystemVersion />} />
            <Route path="*" element={<Home />} />
            <Route
              path="coaching/management"
              element={<CoachingManagement />}
            />
            <Route path="coaching/chat/management" element={<CoachingChat />} />
            <Route
              path="coaching/group/management"
              element={<GroupManagement />}
            />
            <Route
              path="coaching/question/management"
              element={<QuestionManagement />}
            />
          </>
        ) : (
          <>
            <Route path="login" element={<Login />} />
            <Route
              path="/"
              element={isLoggedIn ? <Layout /> : <Navigate to="/login" />}
            />
            <Route
              path="*"
              element={isLoggedIn ? <Layout /> : <Navigate to="/login" />}
            />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
