import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import styled, { css, keyframes } from "styled-components";
import axios from "axios";
import "../App.css";
import { useNavigate } from "react-router";
import Pagenation from "../components/Pagenation";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store";
import swal from "sweetalert";
import userSlice from "../slice/user";
export default function Adsence() {
  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [option, setOption] = useState([
    { no: 0, name: "디플톡" },
    { no: 1, name: "동네톡" },
  ]);
  const [cate, setCate] = useState([]);
  const [isChecked, setIsChecked] = useState({});
  const [selectedInsertOption, setSelectedInsertOption] = useState(0);
  const [selectedInsertCate, setSelectedInsertCate] = useState(0);
  const [location, setLocatation] = useState([]);
  const [sublocation, setSublocatation] = useState([]);
  const [selectedCate, setSelectedCate] = useState(1);
  const [selectedLocation, setSelectedLocation] = useState("1100000000");
  const [selectedSublocation, setSelectedSublocation] = useState("");
  const dispatch = useAppDispatch();
  const accesstoken = useSelector((state) => state.user.accesstoken);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  console.log(
    "selectedInsertCateselectedInsertCateselectedInsertCateselectedInsertCate",
    selectedSublocation
  );
  const handlePageChange = (page) => {
    setPage(page);
  };
  const handleCheckAll = (e) => {
    const newChecked = {};
    getAdsenceList.data.forEach((item) => {
      newChecked[item._id] = e.target.checked;
    });
    setIsChecked(newChecked); // 복사한 isChecked 객체를 업데이트
  };
  const handleCheck = (e, id) => {
    const newChecked = { ...isChecked }; // 현재 isChecked 객체 복사
    newChecked[id] = e.target.checked;
    setIsChecked(newChecked);
  };

  const handleDelete = async () => {
    const checkedPostNos = [];
    Object.keys(isChecked).forEach((key) => {
      if (isChecked[key]) {
        const item = list.find((item) => item._id === String(key));
        console.log(item);
        checkedPostNos.push(item._id);
      }
    });
    console.log("checkedPostNos", checkedPostNos);

    swal({
      title: "정말 삭제하시겠습니까?",
      text: "삭제된 게시물은 복구할 수 없습니다!",
      icon: "warning",
      buttons: ["취소", "삭제"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await axios
            .post(
              `${
                process.env.NODE_ENV == "development"
                  ? "http://localhost:2005"
                  : "https://dplanit.dplanit.co.kr"
              }/admin/talk/addelete`,
              { contentno: checkedPostNos },
              {
                headers: {
                  Authorization: localStorage.getItem("accessToken"),
                },
                withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
              }
            )
            .then((response) => {
              swal("삭제되었습니다!", {
                icon: "success",
              });
              setList((prevItemList) =>
                prevItemList.filter(
                  (item) => !checkedPostNos.includes(item._id)
                )
              );
              setIsChecked({});
            });
        } catch (error) {
          const response = await axios.get(
            `${
              process.env.NODE_ENV == "development"
                ? "http://localhost:2005"
                : "https://dplanit.dplanit.co.kr"
            }/admin/refresh`,
            {
              headers: {
                Authorization: localStorage.getItem("accessToken"),
              },
              withCredentials: true,
            }
          );

          const newAct = response.data.data.Authorization;
          localStorage.setItem("accessToken", newAct);
          dispatch(
            userSlice.actions.updateAccessToken({
              Authorization: localStorage.getItem("accessToken"),
            })
          );
          const response_2 = await axios
            .post(
              `${
                process.env.NODE_ENV == "development"
                  ? "http://localhost:2005"
                  : "https://dplanit.dplanit.co.kr"
              }/admin/talk/addelete`,
              { contentno: checkedPostNos },
              {
                headers: {
                  Authorization: localStorage.getItem("accessToken"),
                },
                withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
              }
            )
            .then((response) => {
              swal("삭제되었습니다!", {
                icon: "success",
              });
              setList((prevItemList) => ({
                ...prevItemList,
                data: prevItemList.filter(
                  (item) => !checkedPostNos.includes(item._id)
                ),
              }));
              setIsChecked({});
            })
            .catch((err) => {
              if (response_2.data.popdata.poptext) {
                swal(response.data.popdata.poptext, { icon: "error" });
              }
            });
        }
      } else {
        swal("취소되었습니다.");
      }
    });
  };
  const getAdsenceList = async (offset) => {
    try {
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/talkad`,
          {
            page: offset,
            sbjcode: selectedInsertCate,
            location: selectedSublocation,
          },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          console.log(res.data.data);
          setList(res.data.data.data);
          setCount(res.data.data.cnt);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.accesstoken;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/talkad`,
          {
            page: offset,
            sbjcode: selectedInsertCate,
            location: selectedSublocation,
          },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setList(res.data.data.data);
          setCount(res.data.data.cnt);
        });
    }
  };
  const getCateList = async () => {
    try {
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/talk/category`,
          { type: selectedInsertOption },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          console.log("catecatecatecatecatecatecatecate", res.data.data);
          setCate(res.data.data);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.accesstoken;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/talk/category`,
          { type: selectedInsertOption },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setCate(res.data.data);
        });
    }
  };
  const handleLocation = async (newMemo, no) => {
    try {
      await axios
        .get(
          `${"https://grpc-proxy-server-mkvo6j4wsq-du.a.run.app/v1/regcodes?regcode_pattern=**00000000"}`,
          { page: page, sbjcode: selectedCate },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          console.log(
            "LocalLocalLocalLocalLocalLocalLocalLocalLocal",
            res.data.regcodes
          );
          setLocatation(res.data.regcodes);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .get(
          `${"https://grpc-proxy-server-mkvo6j4wsq-du.a.run.app/v1/regcodes?regcode_pattern=**00000000"}`,
          { page: page, sbjcode: selectedCate },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          console.log("LocalLocalLocalLocalLocal", res);
          setLocatation(res.data.regcodes);
        });
    }
  };
  console.log(selectedLocation);

  let sub = selectedLocation.slice(0, 2);
  console.log(sub);
  const handleSubLocation = async (selectedLocation) => {
    try {
      await axios
        .get(
          `${`https://grpc-proxy-server-mkvo6j4wsq-du.a.run.app/v1/regcodes?regcode_pattern=${sub}***00000`}`,
          { page: page, sbjcode: selectedCate },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          console.log(
            "handleSubLocationhandleSubLocationhandleSubLocationhandleSubLocationhandleSubLocation",
            res.data.regcodes
          );
          var regdatas = res.data.regcodes;
          var temparr = new Array();
          for (var i = 0; i < regdatas.length; i++) {
            if (regdatas[i].code.substring(2, regdatas.length) == "00000000") {
              continue;
            }
            if (regdatas[i].code[4] != "0") {
              temparr.push(regdatas[i]);
              continue;
            }
            var isfind = false;
            for (var j = 0; j < regdatas.length; j++) {
              if (j == i) continue;

              if (
                regdatas[i].code.substring(0, 4) ==
                regdatas[j].code.substring(0, 4)
              ) {
                isfind = true;
                break;
              }
            }
            if (!isfind) temparr.push(regdatas[i]);
          }
          console.log(temparr);
          setSublocatation(temparr);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .get(
          `${`https://grpc-proxy-server-mkvo6j4wsq-du.a.run.app/v1/regcodes?regcode_pattern=${sub}***00000`}`,
          { page: page, sbjcode: selectedCate },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          var regdatas = res.data.regcodes;
          var temparr = new Array();
          for (var i = 0; i < regdatas.length; i++) {
            if (regdatas[i].code.substring(2, regdatas.length) == "00000000") {
              continue;
            }
            if (regdatas[i].code[4] != "0") {
              temparr.push(regdatas[i]);
              continue;
            }
            var isfind = false;
            for (var j = 0; j < regdatas.length; j++) {
              if (j == i) continue;

              if (
                regdatas[i].code.substring(0, 4) ==
                regdatas[j].code.substring(0, 4)
              ) {
                isfind = true;
                break;
              }
            }
            if (!isfind) temparr.push(regdatas[i]);
          }
          console.log(temparr);
        });
    }
  };
  const handleSelectInsertOption = (e, type) => {
    setSelectedInsertOption(e.target.value);
  };
  const handleSelectInsertCate = (e, type) => {
    setSelectedInsertCate(e.target.value);
  };
  const handleSelectLocate = (e, type) => {
    setSelectedLocation(e.target.value);
  };
  const handleSelectSubLocate = (e, type) => {
    setSelectedSublocation(e.target.value);
  };
  useEffect(() => {
    const offset = (page - 1) * itemsPerPage;
    getAdsenceList(offset);
    getCateList();
  }, [page, selectedInsertOption, selectedInsertCate, selectedSublocation]);
  console.log("selectedCate : ", selectedSublocation);

  useEffect(() => {
    handleLocation();
    handleSubLocation(selectedSublocation);
  }, [selectedLocation]);
  console.log("lits : ", list);
  return (
    <Layout
      children={
        <>
          <HomeContainer>
            <HorizontalContainer>
              <text
                style={{
                  fontSize: "30px",
                  fontFamily: "NanumBarunGothic",
                  fontWeight: 600,
                }}
              >
                톡광고 관리
              </text>
              <CurrentPlaceContainer>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <text
                    style={{
                      color: "#888",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    홈 &nbsp;&nbsp;
                  </text>
                  <img
                    src={process.env.PUBLIC_URL + "/ico_arrow_right_gray.png"}
                    style={{ Width: "19px", height: "19px" }}
                  />
                  <text
                    style={{
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    &nbsp;&nbsp;톡광고 관리
                  </text>
                </div>
              </CurrentPlaceContainer>
            </HorizontalContainer>
            <div style={{ padding: "30px 60px 30px 60px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  총<b style={{ color: "#FF7D3B" }}> {count}</b>개
                </div>
                <div>
                  <ColorButton
                    style={{
                      background: "#fff",
                      color: "#FF4D43",
                      borderColor: "#FF4D43",
                    }}
                    onClick={handleDelete}
                  >
                    <text>선택삭제</text>
                  </ColorButton>
                  <ColorButton
                    style={{
                      background: "#fff",
                      color: "#4674FE",
                      borderColor: "#4674FE",
                    }}
                    onClick={() => {
                      navigate("write");
                    }}
                  >
                    <text>등록하기</text>
                  </ColorButton>
                </div>
              </div>
              <hr
                style={{
                  border: "0.5px solid #E6E7EB",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              />
              <Select
                value={selectedInsertOption}
                onChange={handleSelectInsertOption}
              >
                {option.map((item) => (
                  <option key={item.id} value={item.no}>
                    {item.name}
                  </option>
                ))}
              </Select>
              <Select
                value={selectedInsertCate}
                onChange={handleSelectInsertCate}
              >
                {cate.map((item) => (
                  <option key={item.id} value={item.code}>
                    {item.subject}
                  </option>
                ))}
              </Select>
              {selectedInsertOption == 1 && (
                <>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label>시</label>
                    <Select
                      value={selectedLocation}
                      onChange={handleSelectLocate}
                    >
                      {location.map((item) => (
                        <option key={item.name} value={item.code}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label>군/구</label>
                    <Select
                      value={selectedSublocation}
                      onChange={handleSelectSubLocate}
                    >
                      {sublocation.map((item, index) => (
                        <option key={item.name} value={item.code}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                </>
              )}

              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }}>
                        <input type="checkbox" onChange={handleCheckAll} />
                      </th>
                      <th>내용</th>
                      <th>카테고리</th>
                      <th>작성 일자</th>
                      <th>만료 일자</th>
                      <th>위치</th>
                      <th>타입</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list &&
                      list.map((k, n) => (
                        <tr key={k._id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={isChecked[k._id]}
                              onChange={(e) => handleCheck(e, k._id)}
                            />
                          </td>
                          <td
                          onClick={() =>
                            navigate("write", {
                              state: {
                                title: k.title,
                                content: k.text,
                                contentLink: k.storylink,
                                _id: k._id,
                                mainimg: k.image,
                                type: k.type,
                                subject: k.subject,
                                subjectcode: k.subjectcode,
                                location: k.location,
                                locationname: k.locationname,
                                date: k.date.split("T")[0],
                                isEdit: true,
                              },
                            })
                          }
                          style={{ cursor: "pointer" }}
                          >
                            {k.text}
                          </td>
                          <td>{k.subject}</td>
                          <td>
                            {new Date(k.createdAt).toLocaleString("ko-KR", {
                              hour12: false,
                            })}
                          </td>
                          <td>
                            {new Date(k.date).toLocaleString("ko-KR", {
                              hour12: false,
                            })}
                          </td>
                          <td>
                            {k.locationname == 0 ? "디플톡" : k.locationname}
                          </td>
                          <td>{k.TYPE == "ad" ? "정보형" : "광고형"}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </TableContainer>
              <Pagenation
                page={page}
                itemsPerPage={itemsPerPage}
                number={count} // 총 아이템 갯수
                handlePageChange={handlePageChange} // 페이지 변경을 핸들링하는 함수
              />
            </div>
          </HomeContainer>
        </>
      }
    ></Layout>
  );
}
const HomeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 10px;

  > * {
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`;
const HorizontalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 30px 60px 30px 60px;
`;
const SerchContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-color: #e9eaee;
  line-height: 43px;
  border-width: 1;
  border-style: solid;
  height: 52px;
  width: 314px;
  left: 0px;
  top: 0px;
  border-radius: 44px;
  justify-content: center;
  align-content: center;
`;
const CurrentPlaceContainer = styled.div`
  display: flex;
  align-items: center;
`;
const PastText = styled.div`
  font-size: 14px;
  color: #888888;
`;
const Select = styled.select`
  margin: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #eee; /* 아래쪽 테두리만 적용 */
  padding: 0.5rem;
  height: 34px;
  width: 360px;
  cursor: pointer;
`;
const TableContainer = styled.div`
  font-family: "NanumBarunGothicOTF";
  font-size: 16px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto 50px;
  grid-gap: 10px;
  margin-top: 10px;
  margin-left: 10px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: "100%";

  th,
  td {
    border: 1px solid #e9eaee;
    padding: 8px;
    text-align: center;
    height: 28px;
  }

  th {
    background-color: #f2f2f2;
    height: 28px;
    font-weight: 400;
  }
  grid-column: 1 / span 9;
`;
const Image = styled.img`
  height: 145px;
  width: 288px;
  position: relative;
  z-index: 1;
`;

const ColorButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.0001);
  border: 1px solid #4674fe;
  border-radius: 20px;
  cursor: pointer;
  height: 34px;
  width: 101px;
  border-radius: 20px;
`;
