import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CustomButton from "../CustomButton";
import axios from "axios";
import swal from "sweetalert";
import SelectBox from "../SelectBox";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import userSlice from "../../slice/user";
export default function AnserContainer(props) {
  const [answerValue, setAnswerValue] = useState("");
  const [newState, setNewState] = useState(null);
  const dispatch = useAppDispatch();
  const accesstoken = useSelector((state) => state.user.accesstoken);
  let media = "https://media.dplanit.co.kr";
  useEffect(() => {}, []);
  const insertAnswer = async (qnano, reply, state) => {
    try {
      await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/qna/reply`,
        { qnano, reply, state },
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      swal("등록되었습니다!", { icon: "success" });
      setAnswerValue("");
      setNewState(null);
      props.setLoading(!props.loading);
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      console.log(response);
      if (response.data.popdata.poptext) {
        swal(response.data.popdata.poptext, { icon: "error" });
        return;
      }
      const newAct = response.data.data.accesstoken;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      const response_2 = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/qna/reply`,
        { qnano, reply, state },
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      if (response_2.data.popdata.poptext) {
        swal(response_2.data.popdata.poptext, { icon: "error" });
        return;
      }
      swal("등록되었습니다!", { icon: "success" });
      setAnswerValue("");
      setNewState(null);
      props.setLoading(!props.loading);
    }
  };

  return (
    <>
      <hr style={{ border: "0.5px solid #E6E7EB" }} />
      <HorizontalContainer>
        <div>
          <WriterName>작성자 : {props.userName}</WriterName>
        </div>

        <ItemCntainer>
          <DateText>{props.writeDate}</DateText>
        </ItemCntainer>
      </HorizontalContainer>

      <BodyContainer>
        <BodyText>{props.text}</BodyText>
        <br />
        <BodyText>
          <b>대댓글</b>
          <br />
          {props.subcomment}
          <div>
            {props.image && <img src={media + props.image} alt="description" />}
          </div>
        </BodyText>
      </BodyContainer>

      {(props.state === 0 || props.state === 2) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "40px",
          }}
        ></div>
      )}
    </>
  );
}

const HorizontalContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
`;
const ItemCntainer = styled.div`
  display: flex;
  align-items: center;
`;
const WriterName = styled.text`
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
`;
const WriterDate = styled.text`
  font-size: 16px;
  font-weight: 300;
  line-height: 18px;
`;
const DateText = styled.text`
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
`;
const BodyText = styled.div`
  margin-left: 24px;
  margin-top: 15px;
  margin-bottom: 15px;
`;
const BodyContainer = styled.div`
  display: block;
  width: 100%;
  height: 98px;
  background: #f5f5f5;
  overflow: auto;
`;
const AnswerBody = styled.textarea`
  margin-top: 10px;
  display: flex;
  width: 90%;
  height: 77px;
  background: #ffffff;
  border: 1px solid #e9eaee;
  resize: vertical; /* optional - allows user to resize vertically */
`;
