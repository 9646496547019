import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import styled, { css, keyframes } from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import ToggleButton from "../components/ToggleButton";
import Pagenation from "../components/Pagenation";
import ModalComponent from "../components/ModalComponent";
import PreviewModal from "../components/PreviewModal";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store";
import userSlice from "../slice/user";
const ITEMSPERPAGE = 10;
export default function Post() {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [ShowModal, setShowModal] = useState(false);
  const [modalInfo, setmodalInfo] = useState("");
  const [page, setPage] = useState(1);
  const [postList, setPostList] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState({});
  const [cate, setCate] = useState([]);
  const [selectedInsertCate, setSelectedInsertCate] = useState(1);
  const [selectedUpdateCate, setSelectedUpdateCate] = useState(1);
  const [selectedDeleteCate, setSelectedDeleteCate] = useState(1);
  const [isVertical, setIsVertical] = useState([
    { value: null, label: "-- Select an option --" },
    { value: 0, label: "가로" },
    { value: 1, label: "세로" },
  ]);
  const [selectedVertical, setSelectedVertical] = useState(0);
  const [newInsertCate, setNewInsertCate] = useState("");
  const [maxCateItem, setMaxCateItem] = useState(0);
  const dispatch = useAppDispatch();
  const accesstoken = useSelector((state) => state.user.accesstoken);

  const handleCheck = (e, id) => {
    const newChecked = { ...isChecked }; // 현재 isChecked 객체 복사
    newChecked[id] = e.target.checked;
    setIsChecked(newChecked);
  };
  const handleCheckAll = (e) => {
    const newChecked = {};
    postList.data.forEach((item) => {
      newChecked[item.storyno] = e.target.checked;
    });
    setIsChecked(newChecked); // 복사한 isChecked 객체를 업데이트
  };

  const modalRef = useRef(null);

  useEffect(() => {
    getCateList();
    getPostList(page);
  }, [page, loading]);
  const closeModal = (e) => {
    setShowModal(false);
  };
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleUpdate = async () => {
    await axios
      .post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/story/category/update`,
        {
          cateno: selectedUpdateCate,
          isVertical: selectedVertical,
          width: width,
          height: height,
        },
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      )
      .then((res) => {
        swal("업데이트 되었습니다!", {
          icon: "success",
        });
        setWidth(0);
        setHeight(0);
        setSelectedUpdateCate(1);
        setSelectedVertical(0);
      });
  };
  const handleDelete = async () => {
    const checkedPostNos = [];
    Object.keys(isChecked).forEach((key) => {
      if (isChecked[key]) {
        const post = postList.data.find((post) => post.storyno === Number(key));
        checkedPostNos.push(post.storyno);
      }
    });
    console.log("checkedPostNos", checkedPostNos);
    swal({
      title: "정말 삭제하시겠습니까?",
      text: "삭제된 게시물은 복구할 수 없습니다!",
      icon: "warning",
      buttons: ["취소", "삭제"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await axios
            .post(
              `${
                process.env.NODE_ENV == "development"
                  ? "http://localhost:2005"
                  : "https://dplanit.dplanit.co.kr"
              }/admin/story/delete`,
              { storyno: checkedPostNos },
              {
                headers: {
                  Authorization: localStorage.getItem("accessToken"),
                },
                withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
              }
            )
            .then((response) => {
              swal("삭제되었습니다!", {
                icon: "success",
              });
              setPostList((prevPostList) => ({
                ...prevPostList,
                data: prevPostList.data.filter(
                  (post) => !checkedPostNos.includes(post.storyno)
                ),
              }));
              setIsChecked({});
            });
        } catch (error) {
          const response = await axios.get(
            `${
              process.env.NODE_ENV == "development"
                ? "http://localhost:2005"
                : "https://dplanit.dplanit.co.kr"
            }/admin/refresh`,
            {
              headers: {
                Authorization: localStorage.getItem("accessToken"),
              },
              withCredentials: true,
            }
          );

          const newAct = response.data.data.Authorization;
          localStorage.setItem("accessToken", newAct);
          dispatch(
            userSlice.actions.updateAccessToken({
              Authorization: localStorage.getItem("accessToken"),
            })
          );
          const response_2 = await axios
            .post(
              `${
                process.env.NODE_ENV == "development"
                  ? "http://localhost:2005"
                  : "https://dplanit.dplanit.co.kr"
              }/admin/story/delete`,
              { storyno: checkedPostNos },
              {
                headers: {
                  Authorization: localStorage.getItem("accessToken"),
                },
                withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
              }
            )
            .then((response) => {
              swal("삭제되었습니다!", {
                icon: "success",
              });
              setPostList((prevPostList) => ({
                ...prevPostList,
                data: prevPostList.data.filter(
                  (post) => !checkedPostNos.includes(post.storyno)
                ),
              }));
              setIsChecked({});
            })
            .catch((err) => {
              if (response_2.data.popdata.poptext) {
                swal(response.data.popdata.poptext, { icon: "error" });
              }
            });
        }
      } else {
        swal("취소되었습니다.");
      }
    });
  };

  const getModalInfo = async (postno) => {
    try {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/story/preview/${postno}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      const modifiedContent = response.data.replace(
        /src="\/upload/g,
        `src="${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/upload`
      );
      return modifiedContent;
    } catch (error) {
      const response2 = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response2.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/post/preview/${postno}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      const modifiedContent = response.data.replace(
        /src="\/upload/g,
        `src="${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/upload`
      );
      return modifiedContent;
    }
  };
  const getCateList = async () => {
    try {
      await axios
        .get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/story/category`,
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          console.log("catecatecatecate", res.data.data);
          setCate(res.data.data);
        });
    } catch (error) {
      const response = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.accesstoken;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/story/category`,
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setCate(res.data.data);
        });
    }
  };
  const getPostList = async (offset) => {
    try {
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/story/list`,
          { offset },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          console.log(res.data.data);
          setPostList(res.data.data);
          setCount(res.data.data.cnt[0].cnt);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.accesstoken;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/story/list`,
          { offset },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setPostList(res.data.data);
          setCount(res.data.data.cnt[0].cnt);
        });
    }
  };
  const openModal = async (postno) => {
    const data = await getModalInfo(postno);
    setmodalInfo(data);
    setShowModal(true);
  };
  const handlePageChange = (page) => {
    setPage(page);
  };
  const handleWidthChange = (e) => {
    const inputValue = e.target.value;
    setWidth(inputValue);
  };
  const handleheightChange = (e) => {
    const inputValue = e.target.value;
    setWidth(inputValue);
  };
  const handleNewInsertCate = (e) => {
    const inputValue = e.target.value;
    setHeight(inputValue);
  };
  const handleSelectInsertCate = (e, type) => {
    setSelectedInsertCate(e.target.value);
  };
  const handleSelectUpdateCate = (e, type) => {
    setSelectedUpdateCate(e.target.value);
  };
  const handleSelectDeleteCate = (e, type) => {
    setSelectedDeleteCate(e.target.value);
  };
  const handleSelectVertical = (e) => {
    setSelectedVertical(e.target.value);
  };
  return (
    <Layout
      children={
        <>
          <HomeContainer>
            <HorizontalContainer>
              <text
                style={{
                  fontSize: "30px",
                  fontFamily: "NanumBarunGothic",
                  fontWeight: 600,
                }}
              >
                포스트 관리
              </text>

              <CurrentPlaceContainer>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <text
                    style={{
                      color: "#888",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    홈 &nbsp;&nbsp;
                  </text>
                  <img
                    src={process.env.PUBLIC_URL + "/ico_arrow_right_gray.png"}
                    style={{ Width: "19px", height: "19px" }}
                  />
                  <text
                    style={{
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    &nbsp;&nbsp;포스트 관리
                  </text>
                </div>
              </CurrentPlaceContainer>
            </HorizontalContainer>

            {/* {카테고리 추가} */}
            {/* <HorizontalContainer>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label>카테고리</label>
                <Select
                  value={selectedInsertCate}
                  onChange={handleSelectInsertCate}
                >
                  {cate.map((item) => (
                    <option key={item.id} value={item.cateNo}>
                      {item.cateName}
                    </option>
                  ))}
                </Select>
                <label>가로/세로</label>
                <Select
                  value={selectedVertical}
                  onChange={handleSelectVertical}
                >
                  {isVertical.map((item) => (
                    <option key={item.id} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
                <TextInput
                  type="number"
                  placeholder="너비"
                  value={width}
                  onChange={handleWidthChange}
                />
                <TextInput
                  type="text"
                  placeholder="높이"
                  value={height}
                  onChange={handleheightChange}
                />

                <ColorButton
                  style={{
                    background: "#fff",
                    color: "#EC6D39",
                    borderColor: "#EC6D39",
                  }}
                  onClick={handleUpdate}
                >
                  <text>추가</text>
                </ColorButton>
              </div>
            </HorizontalContainer> */}

            {/* {카테고리 업데이트} */}
            {/* <HorizontalContainer>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label>카테고리</label>
                <Select
                  value={selectedUpdateCate}
                  onChange={handleSelectUpdateCate}
                >
                  {cate.map((item) => (
                    <option key={item.id} value={item.cateNo}>
                      {item.cateName}
                    </option>
                  ))}
                </Select>
                <label>가로/세로</label>
                <Select
                  value={selectedVertical}
                  onChange={handleSelectVertical}
                >
                  {isVertical.map((item) => (
                    <option key={item.id} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
                <TextInput
                  type="text"
                  placeholder="너비"
                  value={width}
                  onChange={handleWidthChange}
                />
                <TextInput
                  type="text"
                  placeholder="높이"
                  value={height}
                  onChange={handleheightChange}
                />

                <ColorButton
                  style={{
                    background: "#fff",
                    color: "#EC6D39",
                    borderColor: "#EC6D39",
                  }}
                  onClick={handleUpdate}
                >
                  <text>업데이트</text>
                </ColorButton>
              </div>
            </HorizontalContainer> */}
            {/* {카테고리 삭제} */}
            {/* <HorizontalContainer>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label>카테고리</label>
                <Select
                  value={selectedDeleteCate}
                  onChange={handleSelectDeleteCate}
                >
                  {cate.map((item) => (
                    <option key={item.id} value={item.cateNo}>
                      {item.cateName}
                    </option>
                  ))}
                </Select>
              </div>
              <>
                <ColorButton
                  style={{
                    background: "#fff",
                    color: "#EC6D39",
                    borderColor: "#EC6D39",
                  }}
                  onClick={handleUpdate}
                >
                  <text>삭제</text>
                </ColorButton>
              </>
            </HorizontalContainer> */}
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "38px 60px 60px 60px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                <div>
                  총 <b style={{ color: "#4674FE" }}>{count}</b>건
                </div>
                <div>
                  <ColorButton
                    style={{
                      background: "#fff",
                      color: "#FF4D43",
                      borderColor: "#FF4D43",
                    }}
                    onClick={handleDelete}
                  >
                    <text>선택삭제</text>
                  </ColorButton>
                  <ColorButton
                    style={{
                      background: "#fff",
                      color: "#4674FE",
                      borderColor: "#4674FE",
                    }}
                    onClick={() => {
                      navigate("write");
                    }}
                  >
                    <text>등록하기</text>
                  </ColorButton>
                </div>
              </div>
              <div style={{ marginBottom: "40px" }}>
                <hr style={{ border: "1px solid #E9EAEE" }} />
              </div>
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }}>
                        <input type="checkbox" onChange={handleCheckAll} />
                      </th>
                      <th style={{ width: "26%" }}>제목</th>
                      <th style={{ width: "18%" }}>카테고리</th>
                      <th style={{ width: "18%" }}>등록일</th>
                      <th style={{ width: "18%" }}>게시여부</th>
                      <th style={{ width: "26%" }}>타입</th>
                      <th style={{ width: "18%" }}>미리보기</th>
                    </tr>
                  </thead>
                  <tbody>
                    {postList.data &&
                      postList.data.map((item, idx) => (
                        <tr key={item.id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={isChecked[item.storyno]}
                              onChange={(e) => handleCheck(e, item.storyno)}
                            />
                          </td>
                          <td
                            onClick={() =>
                              navigate("write", {
                                state: {
                                  title: item.title,
                                  content: item.content,
                                  contentLink: item.storylink,
                                  postno: item.storyno,
                                  mainimg: item.mainimg,
                                  thumbimg: item.thumbimg,
                                  homeimg: item.homeimg,
                                  relateimg: item.relateimg,
                                  type: item.type,
                                  writeType: item.writeType,
                                  writerid: item.writerid,
                                  source: item.source,
                                  link: item.link,
                                  summary: item.summary,
                                  isEdit: true,
                                },
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {item.title}
                          </td>
                          <td>
                            {" "}
                            {item.type === 1
                              ? "난임Q&A"
                              : item.type === 2
                              ? "임신 준비 가이드"
                              : item.type === 3
                              ? "디플위키"
                              : "성건강 꿀팁"}
                          </td>
                          <td>{item.date}</td>
                          <td>
                            <ToggleButton
                              isActive={Boolean(item.show)}
                              storyNo={item.storyno}
                              onClick={() => {
                                setLoading(!loading);
                              }}
                              key={item.storyno}
                            />
                          </td>
                          <td>
                            {item.writeType === 0
                              ? "기본"
                              : item.writeType === 1
                              ? "유튜브"
                              : "블로그"}
                          </td>
                          <td
                            style={{ color: "#4674FE", cursor: "pointer" }}
                            onClick={() => {
                              openModal(item.storyno);
                            }}
                          >
                            확인
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <Pagenation
                  page={page}
                  itemsPerPage={15}
                  number={count}
                  handlePageChange={handlePageChange}
                />
              </TableContainer>
            </div>
          </HomeContainer>
          {ShowModal && (
            <PreviewModal
              closeModal={closeModal}
              title="포스트 미리보기"
              isOpen={ShowModal}
              content={modalInfo}
            />
          )}
        </>
      }
    ></Layout>
  );
}
const HomeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 10px;

  > * {
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
`;
const HorizontalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 30px 60px 30px 60px;
`;
const CurrentPlaceContainer = styled.div`
  display: flex;
  align-items: center;
`;
const PastText = styled.div`
  color: #888888;
`;
const TableContainer = styled.div`
  font-family: "NanumBarunGothicOTF";
  font-size: 16px;
  display: flex;
  flex-direction: column;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto 50px;
  grid-gap: 10px;
  align-items: center;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    border: 1px solid #e9eaee;
    padding: 8px;
    text-align: center;
    height: 28px;
  }

  th {
    background-color: #f2f2f2;
    height: 28px;
    font-weight: 400;
  }
  grid-column: 1 / span 6;
`;

const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: #fff;
  width: 400px;
  padding: 20px;
  border-radius: 5px;
`;

const ModalClose = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
`;

const ModalTitle = styled.h3`
  margin-top: 0;
`;

const ModalBody = styled.div`
  margin-bottom: 20px;
  overflow-y: scroll; /* 세로 스크롤바 표시 */
  max-height: 500px; /* 모달 창의 최대 높이 */
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ColorButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.0001);
  border: 1px solid #4674fe;
  border-radius: 20px;
  cursor: pointer;
  height: 34px;
  width: 101px;
  border-radius: 20px;
`;
const Select = styled.select`
  margin: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #eee; /* 아래쪽 테두리만 적용 */
  padding: 0.5rem;
  height: 34px;
  width: 360px;
  cursor: pointer;
`;
const TextInput = styled.input`
  margin: 10px;
  width: 23%;
  height: 48px;
  padding: 0 10px;
  background: #ffffff;
  border: 1px solid #e9eaee;
  margin-right: 20px;
`;
