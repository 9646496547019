import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Layout from "./Layout";
import styled, { css, keyframes } from "styled-components";
import axios from "axios";
import CustomButton from "./CustomButton";
import "../App.css";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Pagenation from "../components/Pagenation";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store";
import userSlice from "../slice/user";
export default function DpTalk(props) {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [risk, setRisk] = useState([]);
  const [treat, setTreat] = useState([]);
  const [diag, setDiag] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [memo, setMemo] = useState([]);
  const [cate, setCate] = useState([]);
  const [userno, setUserno] = useState([]);
  const [isChecked, setIsChecked] = useState({});
  const [newMemo, setNewMemo] = useState("");
  const [selectedInsertCate, setSelectedInsertCate] = useState(1);
  const [selectedUpdateCate, setSelectedUpdateCate] = useState(1);
  const [selectedCate, setSelectedCate] = useState(1);
  const dispatch = useAppDispatch();
  const accesstoken = useSelector((state) => state.user.accesstoken);
  const handlePageChange = (page) => {
    setPage(page);
  };
  const handleSelectCate = (e, type) => {
    setSelectedCate(e.target.value);
  };
  const handleCheck = (e, id) => {
    const newChecked = { ...isChecked }; // 현재 isChecked 객체 복사
    newChecked[id] = e.target.checked;
    setIsChecked(newChecked);
  };

  const handleCheckAll = (e) => {
    const newChecked = {};
    list.forEach((item) => {
      newChecked[item._id] = e.target.checked;
    });
    setIsChecked(newChecked); // 복사한 isChecked 객체를 업데이트
  };
  const handleDelete = async () => {
    const checkedPostNos = [];
    Object.keys(isChecked).forEach((key) => {
      if (isChecked[key]) {
        const item = list.find((item) => item._id === String(key));
        console.log(item);
        checkedPostNos.push(item._id);
      }
    });
    console.log("checkedPostNos", checkedPostNos);

    swal({
      title: "정말 삭제하시겠습니까?",
      text: "삭제된 게시물은 복구할 수 없습니다!",
      icon: "warning",
      buttons: ["취소", "삭제"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await axios
            .post(
              `${
                process.env.NODE_ENV == "development"
                  ? "http://localhost:2005"
                  : "https://dplanit.dplanit.co.kr"
              }/admin/talk/delete`,
              { contentno: checkedPostNos },
              {
                headers: {
                  Authorization: localStorage.getItem("accessToken"),
                },
                withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
              }
            )
            .then((response) => {
              swal("삭제되었습니다!", {
                icon: "success",
              });
              setList((prevItemList) =>
                prevItemList.filter(
                  (item) => !checkedPostNos.includes(item._id)
                )
              );
              setIsChecked({});
            });
        } catch (error) {
          const response = await axios.get(
            `${
              process.env.NODE_ENV == "development"
                ? "http://localhost:2005"
                : "https://dplanit.dplanit.co.kr"
            }/admin/refresh`,
            {
              headers: {
                Authorization: localStorage.getItem("accessToken"),
              },
              withCredentials: true,
            }
          );

          const newAct = response.data.data.Authorization;
          localStorage.setItem("accessToken", newAct);
          dispatch(
            userSlice.actions.updateAccessToken({
              Authorization: localStorage.getItem("accessToken"),
            })
          );
          const response_2 = await axios
            .post(
              `${
                process.env.NODE_ENV == "development"
                  ? "http://localhost:2005"
                  : "https://dplanit.dplanit.co.kr"
              }/admin/talk/delete`,
              { contentno: checkedPostNos },
              {
                headers: {
                  Authorization: localStorage.getItem("accessToken"),
                },
                withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
              }
            )
            .then((response) => {
              swal("삭제되었습니다!", {
                icon: "success",
              });
              setList((prevItemList) => ({
                ...prevItemList,
                data: prevItemList.filter(
                  (item) => !checkedPostNos.includes(item._id)
                ),
              }));
              setIsChecked({});
            })
            .catch((err) => {
              if (response_2.data.popdata.poptext) {
                swal(response.data.popdata.poptext, { icon: "error" });
              }
            });
        }
      } else {
        swal("취소되었습니다.");
      }
    });
  };
  useEffect(() => {
    getCateList();
    List(page);
  }, [page, selectedCate]);
  const List = async (no) => {
    try {
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/talk`,
          { page: page, sbjcode: selectedCate },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          console.log("res.data.data.data", res.data.data.data);
          setList(res.data.data.data);
          setCount(res.data.data.cnt);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/talk`,
          { page: page, sbjcode: selectedCate },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          console.log(res.data.data.data);
          setList(res.data.data.data);
          setCount(res.data.data.cnt);
        });
    }
  };

  const handleMemo = async (newMemo, no) => {
    try {
      const response = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2500"
            : "https://dplanit.dplanit.co.kr"
        }/admin/member/memo`,
        { memo: memo, userno: no, type: 0 },
        {
          headers: {
            accesstoken: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      setMemo(memo);
      swal("저장되었습니다");
    } catch (error) {
      await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2500"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            accesstoken: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.accesstoken;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          accesstoken: localStorage.getItem("accessToken"),
        })
      );
      const response = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2500"
            : "https://dplanit.dplanit.co.kr"
        }/admin/member/memo`,
        { memo: memo, userno: no, type: 0 },
        {
          headers: {
            accesstoken: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      setMemo(memo);
      swal("저장되었습니다");
    }
  };
  const getCateList = async () => {
    try {
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/talk/category`,
          { type: 0 },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          console.log("catecatecate", res);
          setCate(res.data.data);
        });
    } catch (error) {
      const response = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.accesstoken;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/talk/category`,
          { type: 0 },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setCate(res.data.data);
        });
    }
  };

  useEffect(() => {
    List();
    // handlememo(props.no)
    return;
  }, [page]);

  const navigation = useNavigate();
  const handleNavigate_member = (no) => {
    navigation("/member/mychart", { state: { no: no } });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "30px 60px 30px 60px",
        }}
      >
        <div
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "30px",
          }}
        >
          총 <b style={{ color: "#FF7D3B" }}>{count}</b>건
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <CustomButton
            title={"삭제하기"}
            color={"#FF4D43"}
            onClick={handleDelete}
          ></CustomButton>
        </div>
        <div>
          <text>디플톡</text>
        </div>

        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <label>카테고리</label>
          <Select value={selectedCate} onChange={handleSelectCate}>
            {cate.map((item) => (
              <option key={item.id} value={item.code}>
                {item.subject}
              </option>
            ))}
          </Select>
        </div>

        <div style={{ marginTop: "20px", marginBottom: "30px" }}>
          <hr
            style={{
              border: "0.5px solid #E6E7EB",
            }}
          />
        </div>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th style={{ width: "5%" }}>
                  <input type="checkbox" onChange={handleCheckAll} />
                </th>
                <th>번호</th>
                <th>제목</th>
                <th>닉네임</th>
                <th>카테고리</th>
                <th>작성 일자</th>
              </tr>
            </thead>
            <tbody>
              {list &&
                list.map((k, n) => (
                  <tr key={k.no}>
                    <td>
                      <input
                        type="checkbox"
                        checked={isChecked[k._id]}
                        onChange={(e) => handleCheck(e, k._id)}
                      />
                    </td>
                    <td>{n + 1}</td>
                    <td
                      onClick={() =>
                        navigate("detail", {
                          state: {
                            title: k.title,
                            content: k.text,
                            nickname: k.usernickname,
                            date: new Date(k.createdAt).toLocaleString(
                              "ko-KR",
                              {
                                hour12: false,
                              }
                            ),
                            isEdit: true,
                            subject: k.subject,
                            id: k._id,
                            image: k.image,
                          },
                        })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {k.title}
                    </td>
                    <td>{k.usernickname}</td>
                    <td>{k.subject}</td>
                    <td>
                      {new Date(k.createdAt).toLocaleString("ko-KR", {
                        hour12: false,
                      })}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </TableContainer>
        <Pagenation
          page={page}
          itemsPerPage={20}
          number={count}
          handlePageChange={handlePageChange}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "30px 60px 30px 60px",
        }}
      >
        <div style={{ marginTop: "20px", marginBottom: "30px" }}>
          <hr
            style={{
              border: "0.5px solid #E6E7EB",
            }}
          />
        </div>
      </div>
    </>
  );
}

const HomeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 10px;
  margin-top: 10px;
  margin-left: 10px;

  > * {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    box-sizing: border-box;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`;
const HorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
`;
const CurrentPlaceContainer = styled.div`
  display: flex;
`;
const PastText = styled.div`
  font-size: 14px;
  color: #888888;
`;
const PastsText = styled.div`
  font-size: 14px;
  color: #888888;
`;
const MemoContainer = styled.div`
  //font-family: "NanumBarunGothicOTF";
  //display: grid;
  line-height: 43px;
  border-width: 1;
  height: 104px;
  width: 1405px;
  left: 0px;
  top: 0px;
  border-radius: 44px;
  justify-content: center;
  align-content: center;
  flex-direction: row;
`;
const TableContainer = styled.div`
  font-family: "NanumBarunGothicOTF";
  font-size: 16px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto 50px;
  grid-gap: 10px;
  margin-top: 10px;
  margin-left: 10px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: "100%";

  th,
  td {
    border: 1px solid #e9eaee;
    padding: 8px;
    text-align: center;
    height: 28px;
  }

  th {
    background-color: #f2f2f2;
    height: 28px;
    font-weight: 400;
  }
  grid-column: 1 / span 9;
`;
const Image = styled.img`
  height: 145px;
  width: 288px;
  position: relative;
  z-index: 1;
`;
const Table2 = styled.table`
  border-collapse: collapse;
  width: 100%;

  tr {
    display: grid;
    grid-template-columns: 160px 4fr 160px 4fr;
    flex-direction: row;
  }

  th,
  td {
    border: 1px solid #e9eaee;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
    text-align: center;
    grid-column: 1 / span 6;
  }

  td:nth-child(1),
  td:nth-child(3) {
    background-color: #f2f2f2;
  }

  td:nth-child(3):last-of-type {
    grid-row-end: span 2;
  }

  td:nth-child(3):last-of-type {
    background-color: #ffff;
  }
`;
const ButtonContainer = styled.button`
  margin-right: 50px;
  padding: 5px 10px;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.0001);
  /* border: 1px solid #9426EA;
  border-radius: 20px;
  cursor: pointer; */
  height: 34px;
  width: 101px;
  /* border-radius: 20px; */
  margin-top: 20px;
  flex-direction: row;
  border: none;
  padding-top: 13px;
`;
const DateText = styled.text`
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
`;
const BodyText = styled.div`
  margin-left: 24px;
  margin-top: 15px;
  margin-bottom: 15px;
`;
const BodyContainer = styled.div`
  display: block;
  width: 100%;
  height: 98px;
  background: #f5f5f5;
  overflow: auto;
`;
const AnserBody = styled.textarea`
  margin-top: 10px;
  display: flex;
  width: 100%;
  height: 77px;
  background: #ffffff;
  border: 1px solid #e9eaee;
  resize: vertical; /* optional - allows user to resize vertically */
`;
const Text = styled.div`
  font-size: 30px;
  font-family: NanumBarunGothicOTF;
  font-weight: 600;
`;
const Tab = styled.div`
  font-size: 30px;
  font-family: NanumBarunGothicOTF;
  font-weight: 600;
  margin-left: 10px;
  box-sizing: border-box;
  //position: absolute;
  right: 67.41%;
  left: 0%;
  bottom: 0%;
  background-color: #ffff;
  border: 2px solid #000000;
  border-radius: 4px;
  width: 174.06px;
  height: 54.43px;
  text-align: center;
  align-content: center;
`;
const TabContainer = styled.div`
  font-family: "NanumBarunGothicOTF";
  font-size: 16px;
  display: flex;
  margin-top: 10px;
  margin-left: 10px;
  align-content: center;
`;
const MemoButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.0001);
  border: 1px solid #9426ea;
  color: #9426ea;
  font-size: 16px;
  border-radius: 20px;
  cursor: pointer;
  height: 34px;
  width: 101px;
  border-radius: 20px;
`;
const Select = styled.select`
  margin: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #eee; /* 아래쪽 테두리만 적용 */
  padding: 0.5rem;
  height: 34px;
  width: 360px;
  cursor: pointer;
`;
