import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Layout from "./Layout";
import styled, { css, keyframes } from "styled-components";
import axios from "axios";
import CustomButton from "./CustomButton";
import "../App.css";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store";
import userSlice from "../slice/user";
export default function Report(props) {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [risk, setRisk] = useState([]);
  const [treat, setTreat] = useState([]);
  const [diag, setDiag] = useState([]);
  const [memo, setMemo] = useState([]);
  const [userno, setUserno] = useState([]);
  const [newMemo, setNewMemo] = useState("");
  const dispatch = useAppDispatch();
  const accesstoken = useSelector((state) => state.user.accesstoken);
  const List = async (no) => {
    try {
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/talk/report`,
          { userno: no },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          console.log("res.data.data.data", res.data.data);
          setList(res.data.data);
          setDiag(res.data.data.diagdata);
          setRisk(res.data.data.riskdata);
          setTreat(res.data.data.treatedata);
          setMemo(res.data.data.memo[0].memo);
          setUserno(res.data.data.data[0].userno);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/talk/report`,
          { userno: no },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          console.log(res.data.data.data);
          setList(res.data.data);
          setDiag(res.data.data.diagdata);
          setRisk(res.data.data.riskdata);
          setTreat(res.data.data.treatedata);
          setMemo(res.data.data.memo[0].memo);
          setUserno(res.data.data.data[0].userno);
        });
    }
  };

  useEffect(() => {
    List();
    // handlememo(props.no)
    return;
  }, []);

  const navigation = useNavigate();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "30px 60px 30px 60px",
        }}
      >
        <text>신고글</text>
        <div style={{ marginTop: "20px", marginBottom: "30px" }}>
          <hr
            style={{
              border: "0.5px solid #E6E7EB",
            }}
          />
        </div>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th>번호</th>
                <th>제목</th>
                <th>닉네임</th>
                <th>카테고리</th>
                <th>작성 일자</th>
                <th>신고 일자</th>
                <th>누적횟수</th>
                <th>신고사유</th>
              </tr>
            </thead>
            <tbody>
              {list &&
                list.map((k, n) => (
                  <tr key={k.no}>
                    <td>{n + 1}</td>
                    <td
                      onClick={() =>
                        navigate("detail", {
                          state: {
                            title: k.title,
                            content: k.text,
                            nickname: k.usernickname,
                            date: new Date(k.createdAt).toLocaleString(
                              "ko-KR",
                              {
                                hour12: false,
                              }
                            ),
                            isEdit: true,
                            subject: k.subject,
                            id: k._id,
                            image: k.image,
                          },
                        })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {k.title}
                    </td>
                    <td>{k.usernickname}</td>
                    <td>{k.subject}</td>
                    <td>
                      {new Date(k.createdAt).toLocaleString("ko-KR", {
                        hour12: false,
                      })}
                    </td>
                    <td>
                      {new Date(k.reportTime).toLocaleString("ko-KR", {
                        hour12: false,
                      })}
                    </td>
                    <td>{k.times}</td>
                    <td>
                      {(() => {
                        switch (parseInt(k.reportype)) {
                          case 0:
                            return "광고 / 홍보글이에요";
                          case 1:
                            return "디플톡에 부적절한 글이에요";
                          case 2:
                            return "사용자를 비방하는 글이에요";
                          case 3:
                            return "중복/도배성 게시글이에요";
                          case 4:
                            return "음란물 또는 불건전한 만남과 대화를 유도하는 글이에요";
                          case 5:
                            return "유출 / 사칭 / 사기와 관련된 글이에요";
                          default:
                            return k.reportype;
                        }
                      })()}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </TableContainer>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "30px 60px 30px 60px",
        }}
      >
        <div style={{ marginTop: "20px", marginBottom: "30px" }}>
          <hr
            style={{
              border: "0.5px solid #E6E7EB",
            }}
          />
        </div>
      </div>
    </>
  );
}

const HomeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 10px;
  margin-top: 10px;
  margin-left: 10px;

  > * {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    box-sizing: border-box;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`;
const HorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
`;
const CurrentPlaceContainer = styled.div`
  display: flex;
`;
const PastText = styled.div`
  font-size: 14px;
  color: #888888;
`;
const PastsText = styled.div`
  font-size: 14px;
  color: #888888;
`;
const MemoContainer = styled.div`
  //font-family: "NanumBarunGothicOTF";
  //display: grid;
  line-height: 43px;
  border-width: 1;
  height: 104px;
  width: 1405px;
  left: 0px;
  top: 0px;
  border-radius: 44px;
  justify-content: center;
  align-content: center;
  flex-direction: row;
`;
const TableContainer = styled.div`
  font-family: "NanumBarunGothicOTF";
  font-size: 16px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto 50px;
  grid-gap: 10px;
  margin-top: 10px;
  margin-left: 10px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: "100%";

  th,
  td {
    border: 1px solid #e9eaee;
    padding: 8px;
    text-align: center;
    height: 28px;
  }

  th {
    background-color: #f2f2f2;
    height: 28px;
    font-weight: 400;
  }
  grid-column: 1 / span 9;
`;
const Image = styled.img`
  height: 145px;
  width: 288px;
  position: relative;
  z-index: 1;
`;
const Table2 = styled.table`
  border-collapse: collapse;
  width: 100%;

  tr {
    display: grid;
    grid-template-columns: 160px 4fr 160px 4fr;
    flex-direction: row;
  }

  th,
  td {
    border: 1px solid #e9eaee;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
    text-align: center;
    grid-column: 1 / span 6;
  }

  td:nth-child(1),
  td:nth-child(3) {
    background-color: #f2f2f2;
  }

  td:nth-child(3):last-of-type {
    grid-row-end: span 2;
  }

  td:nth-child(3):last-of-type {
    background-color: #ffff;
  }
`;
const ButtonContainer = styled.button`
  margin-right: 50px;
  padding: 5px 10px;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.0001);
  /* border: 1px solid #9426EA;
  border-radius: 20px;
  cursor: pointer; */
  height: 34px;
  width: 101px;
  /* border-radius: 20px; */
  margin-top: 20px;
  flex-direction: row;
  border: none;
  padding-top: 13px;
`;
const DateText = styled.text`
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
`;
const BodyText = styled.div`
  margin-left: 24px;
  margin-top: 15px;
  margin-bottom: 15px;
`;
const BodyContainer = styled.div`
  display: block;
  width: 100%;
  height: 98px;
  background: #f5f5f5;
  overflow: auto;
`;
const AnserBody = styled.textarea`
  margin-top: 10px;
  display: flex;
  width: 100%;
  height: 77px;
  background: #ffffff;
  border: 1px solid #e9eaee;
  resize: vertical; /* optional - allows user to resize vertically */
`;
const Text = styled.div`
  font-size: 30px;
  font-family: NanumBarunGothicOTF;
  font-weight: 600;
`;
const Tab = styled.div`
  font-size: 30px;
  font-family: NanumBarunGothicOTF;
  font-weight: 600;
  margin-left: 10px;
  box-sizing: border-box;
  //position: absolute;
  right: 67.41%;
  left: 0%;
  bottom: 0%;
  background-color: #ffff;
  border: 2px solid #000000;
  border-radius: 4px;
  width: 174.06px;
  height: 54.43px;
  text-align: center;
  align-content: center;
`;
const TabContainer = styled.div`
  font-family: "NanumBarunGothicOTF";
  font-size: 16px;
  display: flex;
  margin-top: 10px;
  margin-left: 10px;
  align-content: center;
`;
const MemoButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.0001);
  border: 1px solid #9426ea;
  color: #9426ea;
  font-size: 16px;
  border-radius: 20px;
  cursor: pointer;
  height: 34px;
  width: 101px;
  border-radius: 20px;
`;
