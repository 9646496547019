import React from "react";
import styled from "styled-components";

export default function Home_Coaching() {
  const item = 12;
  return (
    <div style={{ flex: 1 }}>
      <div className={`Home_title`}>코칭</div>
        <img
          src={process.env.PUBLIC_URL + "/ico_reset.png"}
          className={`refresh_button`}
          style={{ cursor: "pointer" }}
          onClick={() => {}}
        />
<div className={`Home_line`}/>
      <HorizontalContainer>
        <div>종료 임박</div>
        <ItemCntainer>
          <ItemText>준비중입니다.</ItemText>
          <div>&nbsp;명</div>
        </ItemCntainer>
      </HorizontalContainer>
      <HorizontalContainer>
        <div>신규 등록</div>
        <ItemCntainer>
          <ItemText>준비중입니다.</ItemText>
          <div>&nbsp;명</div>
        </ItemCntainer>
      </HorizontalContainer>
    </div>
  );
}

const HorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom:19px;
`;
const ItemText = styled.div`
  color: #4674FE;
`;

const ItemCntainer = styled.div`
  display: flex;
  align-items: center;
`;
