import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import ReplyModal from "../ReplyModal";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import userSlice from "../../slice/user";
import swal from "sweetalert";
export default function Home_Question() {
  const [questionList, setQuestionList] = useState([]);
  const [ShowModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [questionNo, setQuestionNo] = useState(null);
  const [questionTitle, setQuestionTitle] = useState(null);
  const dispatch = useAppDispatch();
  const accesstoken = useSelector((state) => state.user.accesstoken);
  const navigate = useNavigate();
  const getQuestionList = async () => {
    try {
      await axios
        .get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/qna/homelist`,
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setQuestionList(res.data.data);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/qna/homelist`,
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setQuestionList(res.data.data);
        });
    }
  };
  useEffect(() => {
    getQuestionList();
  }, [loading]);
  const openModal = async (itemno, itemtitle) => {
    setQuestionNo(itemno);
    setQuestionTitle(itemtitle);
    setShowModal(true);
  };
  console.log("questionListquestionListquestionListquestionList", questionList);
  return (
    <div style={{ flex: 1 }}>
      <div>
        <div className={`Home_title`}>미답변 질문</div>
        <img
          src={process.env.PUBLIC_URL + "/ico_reset.png"}
          className={`refresh_button`}
          style={{ cursor: "pointer" }}
          onClick={() => {
            getQuestionList();
          }}
        />
      </div>

      <div className={`Home_line`} style={{ marginBottom: 28 }} />

      <div style={{ height: 330, overflow: "hidden" }}>
        {questionList &&
          questionList.map((item, index) => (
            <>
              <HorizontalContainer
                style={{ cursor: "pointer" }}
                onClick={() => {
                  openModal(item.no, item.title);
                }}
              >
                <QuestionText>Q.&nbsp;</QuestionText>
                <VarticalContainer>
                  <div>{item.title}</div>
                  <DateText>{item.date}</DateText>
                </VarticalContainer>
                <div style={{ color: "#BDBDBD", marginLeft: "auto" }}>
                  {item.type === 1
                    ? "서비스 문의/오류"
                    : item.type === 2
                    ? "회원 정보"
                    : item.type === 3
                    ? "이벤트"
                    : "기타"}
                </div>
              </HorizontalContainer>
            </>
          ))}
      </div>
      <button
        style={{
          backgroundColor: "#fff",
          border: "1px solid #F2F2F4",
          height: "40px",
          width: "100%",
          bottom: 0,
          cursor: "pointer",
          fontSize: 14,
          textIndent: 0.5,
        }}
        onClick={() => {
          navigate("/coaching/question/management");
        }}
      >
        질문 관리
      </button>
      {ShowModal === true && (
        <ReplyModal
          closeModal={() => setShowModal(false)}
          title={questionTitle}
          isOpen={ShowModal}
          setLoading={setLoading}
          loading={loading}
          questionno={questionNo}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
}
const HorizontalContainer = styled.div`
  &:last-child {
    margin-bottom: 0px;
  }
  display: flex;
  margin-bottom: 19px;
`;
const VarticalContainer = styled.div`
  display: block;
  margin-left: 5.5px;
`;
const ChatButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;
`;
const ChatButtonText = styled.div`
  color: #4674fe;
  margin-top: 9px;
`;
const DateText = styled.div`
  margin-top: 9px;
  margin-left: 0px;
  color: #888888;
  font-weight: 200;
`;
const QuestionText = styled.b`
  color: #4674fe;
  font-weight: 400;
`;
