import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import styled, { css, keyframes } from "styled-components";
import axios from "axios";
import "../App.css";
import { useNavigate } from "react-router";
import Pagenation from "../components/Pagenation";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store";
import swal from "sweetalert";
import userSlice from "../slice/user";
export default function Adsence() {
  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [isChecked, setIsChecked] = useState({});

  const dispatch = useAppDispatch();
  const accesstoken = useSelector((state) => state.user.accesstoken);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setPage(page);
  };
  const handleCheckAll = (e) => {
    const newChecked = {};
    list.forEach((item) => {
      newChecked[item.BANNER_NO] = e.target.checked;
    });
    setIsChecked(newChecked); // 복사한 isChecked 객체를 업데이트
  };
  const handleCheck = (e, id) => {
    const newChecked = { ...isChecked }; // 현재 isChecked 객체 복사
    newChecked[id] = e.target.checked;
    setIsChecked(newChecked);
  };
  const handleDelete = async () => {
    const checkedPostNos = [];
    Object.keys(isChecked).forEach((key) => {
      if (isChecked[key]) {
        const item = list.find((item) => item.BANNER_NO === Number(key));
        console.log(item);
        checkedPostNos.push(item.BANNER_NO);
      }
    });
    console.log("checkedPostNos", checkedPostNos);
    swal({
      title: "정말 삭제하시겠습니까?",
      text: "삭제된 게시물은 복구할 수 없습니다!",
      icon: "warning",
      buttons: ["취소", "삭제"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await axios
            .post(
              `${
                process.env.NODE_ENV == "development"
                  ? "http://localhost:2005"
                  : "https://dplanit.dplanit.co.kr"
              }/admin/banner/delete`,
              { bannerno: checkedPostNos },
              {
                headers: {
                  Authorization: localStorage.getItem("accessToken"),
                },
                withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
              }
            )
            .then((response) => {
              swal("삭제되었습니다!", {
                icon: "success",
              });
              setList((prevItemList) =>
                prevItemList.filter(
                  (item) => !checkedPostNos.includes(item.BANNER_NO)
                )
              );
              setIsChecked({});
            });
        } catch (error) {
          const response = await axios.get(
            `${
              process.env.NODE_ENV == "development"
                ? "http://localhost:2005"
                : "https://dplanit.dplanit.co.kr"
            }/admin/refresh`,
            {
              headers: {
                Authorization: localStorage.getItem("accessToken"),
              },
              withCredentials: true,
            }
          );

          const newAct = response.data.data.Authorization;
          localStorage.setItem("accessToken", newAct);
          dispatch(
            userSlice.actions.updateAccessToken({
              Authorization: localStorage.getItem("accessToken"),
            })
          );
          const response_2 = await axios
            .post(
              `${
                process.env.NODE_ENV == "development"
                  ? "http://localhost:2005"
                  : "https://dplanit.dplanit.co.kr"
              }/admin/banner/delete`,
              { bannerno: checkedPostNos },
              {
                headers: {
                  Authorization: localStorage.getItem("accessToken"),
                },
                withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
              }
            )
            .then((response) => {
              swal("삭제되었습니다!", {
                icon: "success",
              });
              setList((prevItemList) =>
                prevItemList.filter(
                  (item) => !checkedPostNos.includes(item.BANNER_NO)
                )
              );
              setIsChecked({});
            })
            .catch((err) => {
              if (response_2.data.popdata.poptext) {
                swal(response.data.popdata.poptext, { icon: "error" });
              }
            });
        }
      } else {
        swal("취소되었습니다.");
      }
    });
  };
  const getAdsenceList = async (offset) => {
    try {
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/adsence`,
          { offset },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          console.log(res.data.data.cnt[0].cnt);
          setList(res.data.data.data);
          setCount(res.data.data.cnt[0].cnt);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.accesstoken;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/adsence`,
          { offset },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setList(res.data.data);
          setCount(res.data.data.cnt[0].cnt);
        });
    }
  };

  useEffect(() => {
    const offset = (page - 1) * itemsPerPage;
    getAdsenceList(offset);
  }, [page, accesstoken]);
  console.log("List : ", list);

  return (
    <Layout
      children={
        <>
          <HomeContainer>
            <HorizontalContainer>
              <text
                style={{
                  fontSize: "30px",
                  fontFamily: "NanumBarunGothic",
                  fontWeight: 600,
                }}
              >
                배너 광고관리
              </text>
              <CurrentPlaceContainer>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <text
                    style={{
                      color: "#888",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    홈 &nbsp;&nbsp;
                  </text>
                  <img
                    src={process.env.PUBLIC_URL + "/ico_arrow_right_gray.png"}
                    style={{ Width: "19px", height: "19px" }}
                  />
                  <text
                    style={{
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    &nbsp;&nbsp;광고 관리
                  </text>
                </div>
              </CurrentPlaceContainer>
            </HorizontalContainer>
            <div style={{ padding: "30px 60px 30px 60px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  총<b style={{ color: "#FF7D3B" }}> {count}</b>명
                </div>
                <div>
                  <ColorButton
                    style={{
                      background: "#fff",
                      color: "#FF4D43",
                      borderColor: "#FF4D43",
                    }}
                    onClick={handleDelete}
                  >
                    <text>선택삭제</text>
                  </ColorButton>
                  <ColorButton
                    style={{
                      background: "#fff",
                      color: "#4674FE",
                      borderColor: "#4674FE",
                    }}
                    onClick={() => {
                      navigate("write");
                    }}
                  >
                    <text>등록하기</text>
                  </ColorButton>
                </div>
              </div>
              <hr
                style={{
                  border: "0.5px solid #E6E7EB",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              />
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }}>
                        <input type="checkbox" onChange={handleCheckAll} />
                      </th>
                      <th>번호</th>
                      <th>배경 이미지</th>
                      {/* <th>썸네일 이미지</th> */}
                      <th>작성 일자</th>
                      <th>만료 일자</th>
                      <th>순서</th>
                      <th>위치</th>
                      <th>타입</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list &&
                      list.map((k, n) => (
                        <tr key={k.BANNER_NO}>
                          <td>
                            <input
                              type="checkbox"
                              checked={isChecked[k.BANNER_NO]}
                              onChange={(e) => handleCheck(e, k.BANNER_NO)}
                            />
                          </td>
                          <td
                            onClick={() =>
                              navigate("detail", {
                                state: {
                                  content: k.CONTENTS,
                                  postno: k.BANNER_NO,
                                  mainimg: k.BG_IMG,
                                  thumbimg: k.THUMB_IMG,
                                  type: k.TYPE, //댓글형 공지형
                                  writeDate: k.Created_AT,
                                  expireDate: k.EXPIRE_DATE.substring(0, 10),
                                  order: k.ORDER,
                                  position: k.POSITION,
                                  isEdit: true,
                                },
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {n + 1}
                          </td>
                          <td
                            onClick={() =>
                              navigate("write", {
                                state: {
                                  content: k.CONTENTS,
                                  postno: k.BANNER_NO,
                                  mainimg: k.BG_IMG,
                                  thumbimg: k.THUMB_IMG,
                                  type: k.TYPE, //댓글형 공지형
                                  writeDate: k.Created_AT,
                                  expireDate: k.EXPIRE_DATE.substring(0, 10),
                                  order: k.ORDER,
                                  position: k.POSITION,
                                  isEdit: true,
                                },
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <Image
                              src={"https://media.dplanit.co.kr/" + k.BG_IMG}
                              alt="배경이미지"
                            />
                          </td>

                          {/* <td>
                            <Image
                              src={"https://media.dplanit.co.kr/" + k.THUMB_IMG}
                              alt="썸네일 이미지"
                            />
                          </td> */}
                          <td>{k.Created_AT}</td>
                          <td>{k.EXPIRE_DATE}</td>
                          <td>{k.ORDER}</td>
                          <td>
                            {k.POSITION == 0
                              ? "홈 상단 베너"
                              : k.POSITION == 1
                              ? "홈 중간 베너"
                              : k.POSITION == 2
                              ? "홈 모달 팝업"
                              : "포스트 하단 배너"}
                          </td>
                          <td>{k.TYPE == 0 ? "이미지형" : "댓글형"}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </TableContainer>
              <Pagenation
                page={page}
                itemsPerPage={itemsPerPage}
                number={count} // 총 아이템 갯수
                handlePageChange={handlePageChange} // 페이지 변경을 핸들링하는 함수
              />
            </div>
          </HomeContainer>
        </>
      }
    ></Layout>
  );
}
const HomeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 10px;

  > * {
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`;
const HorizontalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 30px 60px 30px 60px;
`;
const SerchContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-color: #e9eaee;
  line-height: 43px;
  border-width: 1;
  border-style: solid;
  height: 52px;
  width: 314px;
  left: 0px;
  top: 0px;
  border-radius: 44px;
  justify-content: center;
  align-content: center;
`;
const CurrentPlaceContainer = styled.div`
  display: flex;
  align-items: center;
`;
const PastText = styled.div`
  font-size: 14px;
  color: #888888;
`;
const TableContainer = styled.div`
  font-family: "NanumBarunGothicOTF";
  font-size: 16px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto 50px;
  grid-gap: 10px;
  margin-top: 10px;
  margin-left: 10px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: "100%";

  th,
  td {
    border: 1px solid #e9eaee;
    padding: 8px;
    text-align: center;
    height: 28px;
  }

  th {
    background-color: #f2f2f2;
    height: 28px;
    font-weight: 400;
  }
  grid-column: 1 / span 9;
`;
const Image = styled.img`
  height: 145px;
  width: 288px;
  position: relative;
  z-index: 1;
`;

const ColorButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.0001);
  border: 1px solid #4674fe;
  border-radius: 20px;
  cursor: pointer;
  height: 34px;
  width: 101px;
  border-radius: 20px;
`;
