import React, { useState } from "react";
import styled from "styled-components";
import SubContainer from "./SubcmtContainer";
import SubcmtContainer from "./SubcmtContainer";
export default function CmtContainer(props) {
  const [showSub, setShowSub] = useState();
  const handleShowSub = () => {
    setShowSub(!showSub);
  };
  let media = "https://media.dplanit.co.kr";
  return (
    <>
      <HorizontalContainer onClick={handleShowSub}>
        <QuesTitle>
          {props.comment}
          <div>
            {props.image && <img src={media + props.image} alt="description" />}
          </div>
        </QuesTitle>
        <ItemCntainer>
          <ArrowImg
            src={process.env.PUBLIC_URL + "/ico_arrow_bottom_black.png"}
          />
        </ItemCntainer>
      </HorizontalContainer>
      {showSub && (
        <>
          {/* <SubContainer
            cmtno={props.cmtno}
            userName={props.userName}
            writeDate={props.writeDate}
            boardtype={props.boardtype}
            comment={props.comment}
            image={props.ORIN_URL}
            state={props.state}
            setLoading={props.setLoading}
            loading={props.loading}
          /> */}
          {props.sub &&
            props.sub.map((subCmtItem) => (
              <SubcmtContainer
                key={subCmtItem.INDEX}
                cmtno={props.COMMENT_INDEX}
                subcmtno={subCmtItem.INDEX}
                subcomment={subCmtItem.COMMENT}
                userName={subCmtItem.NICKNAME}
                writeDate={subCmtItem.WRITE_TIME}
                image={subCmtItem.ORIN_URL}
                setLoading={props.setLoading}
                loading={props.loading}
              />
            ))}
        </>
      )}
      <hr style={{ border: "0.5px solid #E6E7EB" }} />
    </>
  );
}

const HorizontalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 30px 0px 30px 0px;
  cursor: pointer;
`;
const ItemCntainer = styled.div`
  display: flex;
  align-items: center;
`;
const ArrowImg = styled.img`
  height: 20px;
  width: 20.972476959228516px;
  left: 1503.02734375px;
  top: 30px;
  border-radius: 0px;
`;
const QuesTitle = styled.text`
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
`;
const QuesStateText = styled.text`
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
  margin-right: 53.8px;
`;
