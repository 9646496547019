import React, { useState } from "react";
import styled from "styled-components";

export default function textAreaInput(props) {
  const { text, setText } = props;

  const handleTextChange = (e) => {
    const inputValue = e.target.value;
    setText(inputValue);
  };

  return (
    <InputWrapper>
      <TextArea
        type="text"
        placeholder="텍스트를 입력하세요."
        value={text}
        onChange={handleTextChange}
      />
      <TextCounterWrapper></TextCounterWrapper>
    </InputWrapper>
  );
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 0 10px;
  background: #ffffff;
  border: 1px solid #e9eaee;
  border-radius: 5px;
`;

const TextCounterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextCounter = styled.div`
  display: flex; /* 추가 */
  justify-content: center; /* 추가 */
  align-items: center; /* 추가 */
  height: 40px;
  width: 100px;
  background: #f5f5f5;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #e9eaee;
  color: #4674fe;
`;
