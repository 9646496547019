import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import styled from "styled-components";
import Pagenation from "../../components/Pagenation";
import { useLocation } from "react-router";
import axios from "axios";
import CoinPayments from "../../components/CoinPayments";
import Textinput from "../../components/Textinput";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import userSlice from "../../slice/user";

export default function PayManagement(props) {
  const ITEMSPERPAGE = 10;
  const [userdata, setUserdata] = useState([]);
  const [paydata, setPaydata] = useState([]);
  const [usagedata, setUsagedata] = useState();

  const [usagePage, setUsagePage] = useState(1);
  const [paymentPage, setPaymentPage] = useState(1);

  const [payments, setPayments] = useState(null);
  const [paymentsReason, setPaymentsReason] = useState("");
  const [collect, setCollect] = useState(null);
  const [collectReason, setCollectReason] = useState("");
  const location = useLocation();
  const dispatch = useAppDispatch();
  const accesstoken = useSelector((state) => state.user.accesstoken);
  const userno = location.state.no;
  useEffect(() => {
    getPayInfo(userno);
    getPayList(userno, (paymentPage - 1) * ITEMSPERPAGE);
    getUsageList(userno, (usagePage - 1) * ITEMSPERPAGE);
  }, [usagePage, paymentPage, accesstoken]);
  const handlepaymentPageChange = (page) => {
    setPaymentPage(page);
  };
  const handleUsagePageChange = (page) => {
    setUsagePage(page);
  };
  const handleCoin = async (userno, reason, type, coin) => {
    // swal을 이용해 메시지 출력
    const result = await swal({
      title: type == 1 ? "정말 지급하시겠습니까?" : "정말 회수하시겠습니까?",
      text:
        type == 1
          ? `${userdata.name}님에게 ${payments}코인을 지급합니다.`
          : `${userdata.name}님에게 ${collect}코인을 회수합니다.`,
      icon: "info",
      buttons: ["아니오", "예"],
      dangerMode: true,
    });
    // '예' 버튼 클릭시 API 호출
    if (result) {
      try {
        await axios
          .post(
            `${
              process.env.NODE_ENV == "development"
                ? "http://192.168.0.36:2500"
                : "https://dplanit.dplanit.co.kr"
            }/admin/pay/manage`,
            { userno, reason, type, coin },
            {
              headers: {
                accesstoken: localStorage.getItem("accessToken"),
              },
              withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
            }
          )
          .then((res) => {
            setPayments(0);
            setPaymentsReason("");
            setCollect(0);
            setCollectReason("");
            swal(type == 1 ? "지급되었습니다!" : "회수되었습니다!", {
              icon: "success",
            });
          });
      } catch (error) {
        const response = await axios.get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://192.168.0.36:2500"
              : "https://dplanit.dplanit.co.kr"
          }/admin/refresh`,
          {
            headers: {
              accesstoken: localStorage.getItem("accessToken"),
            },
            withCredentials: true,
          }
        );
        if (response.data.popdata.poptext) {
          swal(response.data.popdata.poptext, { icon: "error" });
          return;
        }
        const newAct = response.data.data.accesstoken;
        localStorage.setItem("accessToken", newAct);
        dispatch(
          userSlice.actions.updateAccessToken({
            accesstoken: localStorage.getItem("accessToken"),
          })
        );
        const response_2 = await axios
          .post(
            `${
              process.env.NODE_ENV == "development"
                ? "http://192.168.0.36:2500"
                : "https://dplanit.dplanit.co.kr"
            }/admin/pay/manage`,
            { userno, reason, type, coin },
            {
              headers: {
                accesstoken: localStorage.getItem("accessToken"),
              },
              withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
            }
          )
          .then((res) => {
            setPayments(0);
            setPaymentsReason("");
            setCollect(0);
            setCollectReason("");
            swal(type == 1 ? "지급되었습니다!" : "회수되었습니다!", {
              icon: "success",
            });
          });
        if (response_2.data.popdata.poptext) {
          swal(response_2.data.popdata.poptext, { icon: "error" });
        }
      }
    } else {
      // '취소' 버튼 클릭시 메시지 출력
      swal("취소되었습니다.");
    }
  };

  const getPayInfo = async (userno) => {
    try {
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://192.168.0.36:2500"
              : "https://dplanit.dplanit.co.kr"
          }/admin/pay/detaile`,
          { userno },
          {
            headers: {
              accesstoken: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setUserdata(res.data.data.data[0]);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://192.168.0.36:2500"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            accesstoken: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.accesstoken;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          accesstoken: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://192.168.0.36:2500"
              : "https://dplanit.dplanit.co.kr"
          }/admin/pay/detaile`,
          { userno },
          {
            headers: {
              accesstoken: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setUserdata(res.data.data.data[0]);
        });
    }
  };

  const getPayList = async (userno, offset) => {
    try {
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://192.168.0.36:2500"
              : "https://dplanit.dplanit.co.kr"
          }/admin/pay/payment/list`,
          { userno, offset },
          {
            headers: {
              accesstoken: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setPaydata(res.data.data);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://192.168.0.36:2500"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            accesstoken: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.accesstoken;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          accesstoken: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://192.168.0.36:2500"
              : "https://dplanit.dplanit.co.kr"
          }/admin/pay/payment/list`,
          { userno, offset },
          {
            headers: {
              accesstoken: accesstoken,
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setPaydata(res.data.data);
        });
    }
  };

  const getUsageList = async (userno, offset) => {
    try {
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://192.168.0.36:2500"
              : "https://dplanit.dplanit.co.kr"
          }/admin/pay/use/list`,
          { userno, offset },
          {
            headers: {
              accesstoken: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setUsagedata(res.data.data);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://192.168.0.36:2500"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            accesstoken: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.accesstoken;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          accesstoken: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://192.168.0.36:2500"
              : "https://dplanit.dplanit.co.kr"
          }/admin/pay/use/list`,
          { userno, offset },
          {
            headers: {
              accesstoken: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setUsagedata(res.data.data);
        });
    }
  };
  const unixTimeToDate = (unixTime) => {
    const date = new Date(unixTime);
    date.setDate(date.getDate() - 1);
    let month = `${date.getMonth() + 1}`;
    let day = `${date.getDate()}`;
    const year = date.getFullYear();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let secounds = date.getSeconds();

    if (month.length < 2) {
      month = `0${month}`;
    }
    if (day.length < 2) {
      day = `0${day}`;
    }
    if (hour.toString().length < 2) {
      hour = `0${hour}`;
    }
    if (minutes.toString().length < 2) {
      minutes = `0${minutes}`;
    }
    if (secounds.toString().length < 2) {
      secounds = `0${secounds}`;
    }
    return (
      year +
      "." +
      month +
      "." +
      day +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      secounds
    );
  };

  return (
    <Layout>
      <HomeContainer>
        <HorizontalContainer>
          <text
            style={{
              fontSize: "30px",
              fontFamily: "NanumBarunGothic",
              fontWeight: 600,
            }}
          >
            광고 관리
          </text>
          <CurrentPlaceContainer>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <text
                style={{
                  color: "#888",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                홈 &nbsp;&nbsp;
              </text>
              <img
                src={process.env.PUBLIC_URL + "/ico_arrow_right_gray.png"}
                style={{ Width: "19px", height: "19px" }}
              />
              <text
                style={{
                  color: "#888",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                광고 관리 &nbsp;&nbsp;
              </text>
              <img
                src={process.env.PUBLIC_URL + "/ico_arrow_right_gray.png"}
                style={{ Width: "19px", height: "19px" }}
              />
              <text
                style={{
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                &nbsp;&nbsp;광고 상세
              </text>
            </div>
          </CurrentPlaceContainer>
        </HorizontalContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "60px",
            paddingTop: "30px",
            paddingBottom: "30px",
            paddingRight: "30px",
          }}
        >
          <text style={{ fontWeight: 600 }}>회원정보</text>
          <div style={{ marginBottom: "30px", marginTop: "30px" }}>
            <hr style={{ border: "1px solid #E9EAEE" }} />
          </div>
          <HalfTable>
            <thead>
              <tr>
                <th style={{ backgroundColor: "#E9EAEE" }}>이름</th>
                <th>{userdata.name}</th>
                <th style={{ backgroundColor: "#E9EAEE" }}>닉네임</th>
                <th>{userdata.nickname}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ backgroundColor: "#E9EAEE" }}>생년월일</td>
                <td>{userdata.birth}</td>
                <td style={{ backgroundColor: "#E9EAEE" }}>보유코인</td>
                <td>{userdata.coin}</td>
              </tr>
            </tbody>
          </HalfTable>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "60px",
            paddingTop: "30px",
            paddingBottom: "30px",
            paddingRight: "30px",
          }}
        >
          <text style={{ fontWeight: 600 }}>코인 관리</text>
          <div style={{ marginBottom: "10px", marginTop: "20px" }}>
            <hr style={{ border: "1px solid #E9EAEE" }} />
          </div>
          <div style={{ flexDirection: "row", display: "flex" }}>
            <CoinPayments
              value={payments}
              setValue={setPayments}
              reason={paymentsReason}
              setReason={setPaymentsReason}
              type={1}
              onCoin={() => {
                handleCoin(userno, paymentsReason, 1, +payments);
              }}
            />
            <CoinPayments
              value={collect}
              setValue={setCollect}
              reason={collectReason}
              setReason={setCollectReason}
              type={0}
              onCoin={() => {
                handleCoin(userno, collectReason, 2, +collect);
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "60px",
            paddingTop: "30px",
            paddingBottom: "30px",
            paddingRight: "30px",
          }}
        >
          <text style={{ fontWeight: 600 }}>사용 내역</text>
          <div style={{ marginBottom: "20px", marginTop: "20px" }}>
            <hr style={{ border: "1px solid #E9EAEE" }} />
          </div>
          <Table>
            <thead>
              <tr>
                <th>번호</th>
                <th>사용일</th>
                <th>사용 내역</th>
                <th>코인</th>
                <th>보유코인</th>
              </tr>
            </thead>
            <tbody>
              {usagedata &&
                usagedata.final.map((item, idx) => (
                  <tr key={item.id}>
                    <td>{idx + 1}</td>
                    <td>{unixTimeToDate(item.unix_time)}</td>
                    <td>{item.cointypeTxt}</td>
                    <td>{item.getcoin}</td>
                    <td>{item.leftcoin}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Pagenation
            page={usagePage}
            itemsPerPage={10}
            number={usagedata?.cnt.datas[0].cnt}
            handlePageChange={handleUsagePageChange}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "60px",
            paddingTop: "30px",
            paddingBottom: "30px",
            paddingRight: "30px",
          }}
        >
          <text style={{ fontWeight: 600 }}>광고 내역</text>
          <div style={{ marginBottom: "20px", marginTop: "20px" }}>
            <hr style={{ border: "1px solid #E9EAEE" }} />
          </div>
          <Table>
            <thead>
              <tr>
                <th>번호</th>
                <th>광고일</th>
                <th>광고 내역</th>
                <th>코인</th>
                <th>광고금액</th>
              </tr>
            </thead>
            <tbody>
              {paydata &&
                paydata.map((item, idx) => (
                  <tr key={item.id}>
                    <td>{idx + 1}</td>
                    <td>{unixTimeToDate(item.unix_time)}</td>
                    <td>{item.cointypeTxt}</td>
                    <td>{item.getcoin}</td>
                    <td>{item.amount}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Pagenation
            page={paymentPage}
            itemsPerPage={10}
            number={500}
            handlePageChange={handlepaymentPageChange}
          />
        </div>
      </HomeContainer>
    </Layout>
  );
}
const HomeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 10px;
  margin-top: 10px;
  margin-left: 10px;
  padding-right: 10px;

  > * {
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
`;
const HorizontalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 30px 60px 30px 60px;
`;
const CurrentPlaceContainer = styled.div`
  display: flex;
  align-items: center;
`;
const TitleText = styled.text`
  font-weight: 600;
`;
const HalfTable = styled.table`
  border-collapse: collapse;
  width: 50%;

  thead {
    direction: column;
  }
  th,
  td {
    border: 1px solid #e9eaee;
    padding-left: 24px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
  }

  th {
  }
  grid-column: 1 / span 6;
`;
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    border: 1px solid #e9eaee;
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  }
  grid-column: 1 / span 6;
`;
