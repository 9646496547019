import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import styled, { css, keyframes } from "styled-components";
import axios from "axios";
import ImageSelector from "../components/ImageSelector";
import Textinput from "../components/Textinput";
import Sourceinput from "../components/Sourceinput";
import TextAreainput from "../components/TextAreainput";
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store";
import userSlice from "../slice/user";
import ogs from "open-graph-scraper"; // Import the open-graph-scraper library

export default function Post_Write() {
  const location = useLocation();
  const existTitle = location.state?.title;
  const existContent = location.state?.content;
  const existMainimg =
    location.state?.mainimg &&
    "https://media.dplanit.co.kr/" + location.state?.mainimg;
  const existThumbimg =
    location.state?.thumbimg &&
    "https://media.dplanit.co.kr/" + location.state?.thumbimg;
  const exisHomeimg =
    location.state?.homeimg &&
    "https://media.dplanit.co.kr/" + location.state?.homeimg;
  const existRelateimg =
    location.state?.relateimg &&
    "https://media.dplanit.co.kr/" + location.state?.relateimg;

  const existType = location.state?.type;
  const writetype = location.state?.writeType;
  const postno = location.state?.postno;
  const writerid = location.state?.writerid;
  const existsource = location.state?.source;
  const link = location.state?.link;
  const existSummary = location.state?.summary;
  const isEdit = location.state?.isEdit;
  const storylink = location.state?.contentLink;
  const [category, setCategory] = useState([]);
  const [categoryNo, setCategoryNo] = useState([]);
  const [catergoryIndex, setCategoryIndex] = useState(existType || 0);
  const [title, setTitle] = useState(existTitle || "");
  // const [link, setLink] = useState(existTitle || "");
  const [source, setSource] = useState(existsource || "");
  const [summary, setSummary] = useState(existSummary || "");
  const [sourcelink, setSourcelink] = useState(link || "");
  const [content, setContent] = useState(existContent || "");
  const [WriterList, setWriterList] = useState([]);
  const [selectedWriter, setSelectedWriter] = useState(writerid || null);

  const [image, setImage] = useState(existMainimg || null);
  const [imageData, setImageData] = useState(existMainimg || null);

  const [thumbimg, setThumbimg] = useState(existThumbimg || null);
  const [thumbData, setThumbData] = useState(existThumbimg || null);
  const [homeimg, setHomeimg] = useState(exisHomeimg || null);
  const [homeData, setHomeData] = useState(exisHomeimg || null);
  const [relateimg, setRelateimg] = useState(existRelateimg || null);
  const [relateData, setRelateData] = useState(existRelateimg || null);
  const [loading, setLoading] = useState(false);
  const [writeType, setWriteType] = useState([
    { value: 0, label: "기본" },
    { value: 1, label: "유튜브" },
    { value: 2, label: "블로그" },
  ]);
  const [selectedWriteType, setSelectedWriteType] = useState(writetype || 0);
  const [contentLink, setContentLink] = useState(storylink || "");
  const dispatch = useAppDispatch();
  const editorRef = useRef(null);
  const navigation = useNavigate();
  useEffect(() => {
    Cate();
    getWriterList();
    console.log(writerid);
  }, [loading]);

  const handleSelect = (e) => {
    setSelectedWriter(e.target.value);
  };
  const getWriterList = async () => {
    try {
      await axios
        .get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/story/writer`,
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          const fetchedWriters = res.data.data.map((writer) => ({
            value: writer.id, // 작가 데이터에 적절한 프로퍼티로 변경해 주세요.
            label: writer.name, // 작가 데이터에 적절한 프로퍼티로 변경해 주세요.
          }));
          fetchedWriters.unshift({
            value: null,
            label: "-- Select an option --",
          });
          setWriterList(fetchedWriters);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      if (response.data.popdata.poptext) {
        swal(response.data.popdata.poptext, { icon: "error" });
        return;
      }
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/story/writer`,
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          const fetchedWriters = res.data.data.map((writer) => ({
            value: writer.id, // 작가 데이터에 적절한 프로퍼티로 변경해 주세요.
            label: writer.name, // 작가 데이터에 적절한 프로퍼티로 변경해 주세요.
          }));

          setWriterList(fetchedWriters);
        });
    }
  };

  const handlePaste = async (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");

    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

    if (urlRegex.test(pastedData)) {
      event.preventDefault();

      try {
        setLoading(true);
        const ogData = await makeNewLink(pastedData);
        const ogHTML = ogData.data.data.data;

        const updatedContent =
          content.replace(new RegExp(pastedData, "g"), "") + ogHTML;
        editorRef.current.getInstance().setMarkdown(updatedContent);

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch OG data:", error);
      }
    }
  };
  const Cate = async () => {
    try {
      await axios
        .get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/story/category`,
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setCategory(res.data.data);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      if (response.data.popdata.poptext) {
        swal(response.data.popdata.poptext, { icon: "error" });
        return;
      }
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/story/category`,
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setCategory(res.data.data.cateName);
          setCategoryNo(res.data.data.cateNo);
        });
    }
  };
  const makeNewLink = async (url) => {
    const response = await axios.post(
      `${
        process.env.NODE_ENV == "development"
          ? "http://localhost:2005"
          : "https://dplanit.dplanit.co.kr"
      }/admin/post/newlink`,
      { url: url },
      {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
      }
    );
    return response;
  };
  const handleChange = () => {
    setContent(editorRef.current?.getInstance().getHTML());
  };
  const InsertPost = async (
    title,
    content,
    type,
    thumbimg,
    mainimg,
    homeimg,
    relateimg
  ) => {
    console.log("여기보고있음");

    if (!type) {
      swal("카테고리가 존재하지 않습니다.", { icon: "error" });
      return;
    } else if (!title) {
      swal("제목을 입력해주세요", { icon: "error" });
      return;
    } else if (!content) {
      swal("본문을 입력해주세요", { icon: "error" });
      return;
    } else if (!thumbimg) {
      swal("썸네일 이미지를 입력해주세요", { icon: "error" });
      return;
    } else if (!mainimg) {
      swal("메인 이미지를 입력해주세요", { icon: "error" });
      return;
    } else if (!selectedWriter) {
      swal("작성자를 입력해주세요", { icon: "error" });
      return;
    } else if (!relateimg) {
      swal("연관 이미지를 입력해주세요", { icon: "error" });
      return;
    } else if (!homeimg) {
      swal("홈 이미지를 입력해주세요", { icon: "error" });
      return;
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("type", type);
    formData.append("thumbimg", thumbimg);
    formData.append("mainimg", mainimg);
    formData.append("homeimg", homeimg);
    formData.append("relateimg", relateimg);
    formData.append("writerid", selectedWriter);
    formData.append("source", source);
    formData.append("sourcelink", sourcelink);
    formData.append("summary", summary);
    try {
      await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/story/insert`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      const response_2 = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/story/insert`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      if (response_2.data.popdata.poptext) {
        swal(response_2.data.popdata.poptext, {
          icon: "error",
        });
      }
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    }
  };
  const InsertPost_YOUTUBE = async (
    title,
    thumpath,
    homepath,
    relatepath,
    content,
    contentLink,
    writerid,
    source,
    sourcelink,
    type,
    postType
  ) => {
    if (!title) {
      swal("제목을 입력해주세요", { icon: "error" });
      return;
    } else if (!content) {
      swal("본문을 입력해주세요", { icon: "error" });
      return;
    } else if (!contentLink) {
      swal("컨텐츠링크를 입력해주세요", { icon: "error" });
      return;
    } else if (!thumpath) {
      swal("썸네일 이미지를 입력해주세요", { icon: "error" });
      return;
    } else if (!type) {
      swal("카테고리가 존재하지 않습니다.", { icon: "error" });
      return;
    } else if (!postType) {
      swal("게시글 타입이 존재하지 않습니다.", { icon: "error" });
      return;
    } else if (!relatepath) {
      swal("연관 이미지를 입력해주세요", { icon: "error" });
      return;
    } else if (!homepath) {
      swal("홈 이미지를 입력해주세요", { icon: "error" });
      return;
    } else if (!selectedWriter) {
      swal("작성자를 입력해주세요", { icon: "error" });
      return;
    } else if (!source) {
      swal("출처를 입력해주세요", { icon: "error" });
      return;
    } else if (!sourcelink) {
      swal("출처 링크 를 입력해주세요", { icon: "error" });
      return;
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("contentLink", contentLink);
    formData.append("thumbimg", thumpath);
    formData.append("homeimg", homepath);
    formData.append("relateimg", relatepath);
    formData.append("writerid", selectedWriter);
    formData.append("source", source);
    formData.append("sourcelink", sourcelink);
    formData.append("type", type);
    formData.append("postType", postType);
    try {
      await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/story/insert`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("Authorization", newAct);
      const response_2 = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/story/insert`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      if (response_2.data.popdata.poptext) {
        swal(response_2.data.popdata.poptext, {
          icon: "error",
        });
      }
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    }
  };
  const InsertPost_BLOG = async (
    title,
    thumpath,
    homepath,
    relatepath,
    contentLink,
    type,
    postType
  ) => {
    if (!title) {
      swal("제목을 입력해주세요", { icon: "error" });
      return;
    } else if (!thumpath) {
      swal("썸네일 이미지를 입력해주세요", { icon: "error" });
      return;
    } else if (!contentLink) {
      swal("컨텐츠링크를 입력해주세요", { icon: "error" });
      return;
    } else if (!type) {
      swal("카테고리가 존재하지 않습니다.", { icon: "error" });
      return;
    } else if (!postType) {
      swal("게시글 타입이 존재하지 않습니다.", { icon: "error" });
      return;
    } else if (!homepath) {
      swal("홈 이미지를 입력해주세요", { icon: "error" });
      return;
    } else if (!relatepath) {
      swal("연관 이미지를 입력해주세요", { icon: "error" });
      return;
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("contentLink", contentLink);
    formData.append("thumbimg", thumpath);
    formData.append("homeimg", homepath);
    formData.append("relateimg", relatepath);
    formData.append("type", type);
    formData.append("postType", postType);
    try {
      await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/story/insert`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("Authorization", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      const response_2 = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/story/insert`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      if (response_2.data.popdata.poptext) {
        swal(response_2.data.popdata.poptext, {
          icon: "error",
        });
      }
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    }
  };

  const UpdatePost = async (
    title,
    content,
    type,
    thumbimg,
    mainimg,
    homeimg,
    relateimg
  ) => {
    if (!title) {
      swal("제목을 입력해주세요", { icon: "error" });
    }
    if (!content) {
      swal("본문을 입력해주세요", { icon: "error" });
    }
    if (!thumbimg) {
      swal("썸네일 이미지를 입력해주세요", { icon: "error" });
    }
    if (!mainimg) {
      swal("메인 이미지를 입력해주세요", { icon: "error" });
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("type", type);
    formData.append("thumbimg", thumbimg);
    formData.append("mainimg", mainimg);
    formData.append("homeimg", homeimg);
    formData.append("relateimg", relateimg);
    formData.append("postno", postno);
    formData.append("source", source);
    formData.append("sourcelink", sourcelink);
    formData.append("summary", summary);
    formData.append("writerid", selectedWriter);
    try {
      await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/story/update`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      const response_2 = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/story/update`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      if (response_2.data.popdata.poptext) {
        swal(response_2.data.popdata.poptext, {
          icon: "error",
        });
      }
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    }
  };
  const UpdatePost_YOUTUBE = async (
    title,
    thumpath,
    homepath,
    relatepath,
    content,
    contentLink,
    // source,
    // sourcelink,
    type,
    postType
  ) => {
    if (!title) {
      swal("제목을 입력해주세요", { icon: "error" });
    }
    if (!content) {
      swal("내용을 입력해주세요", { icon: "error" });
    }
    if (!thumbimg) {
      swal("썸네일 이미지를 입력해주세요", { icon: "error" });
    }
    const formData = new FormData();
    formData.append("postno", postno);
    formData.append("title", title);
    formData.append("content", content);
    formData.append("contentLink", contentLink);
    formData.append("thumbimg", thumpath);
    formData.append("homeimg", homepath);
    formData.append("relateimg", relatepath);
    formData.append("writerid", selectedWriter);
    formData.append("source", source);
    formData.append("sourcelink", sourcelink);
    formData.append("type", type);
    try {
      await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/story/update`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      const response_2 = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/story/update`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      if (response_2.data.popdata.poptext) {
        swal(response_2.data.popdata.poptext, {
          icon: "error",
        });
      }
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    }
  };
  const UpdatePost_Blog = async (
    title,
    thumpath,
    homepath,
    relatepath,
    contentLink,
    type,
    postType
  ) => {
    if (!title) {
      swal("제목을 입력해주세요", { icon: "error" });
    }
    if (!thumbimg) {
      swal("썸네일 이미지를 입력해주세요", { icon: "error" });
    }
    const formData = new FormData();
    formData.append("postno", postno);
    formData.append("title", title);
    formData.append("thumbimg", thumpath);
    formData.append("homeimg", homepath);
    formData.append("relateimg", relatepath);
    formData.append("writerid", selectedWriter);
    formData.append("source", source);
    formData.append("contentLink", contentLink);
    formData.append("type", type);
    try {
      await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/story/update`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      const response_2 = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/story/update`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      if (response_2.data.popdata.poptext) {
        swal(response_2.data.popdata.poptext, {
          icon: "error",
        });
      }
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    }
  };
  const handleSelectWriteType = (e) => {
    setSelectedWriteType(e.target.value);
  };
  return (
    <Layout
      children={
        <>
          <VarticalContainer>
            <HeaderCon>
              <HorizontalContainer>
                <MainTitle>포스트 등록</MainTitle>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <text style={{ color: "#888" }}>홈 &nbsp;&nbsp;</text>
                  <img
                    src={process.env.PUBLIC_URL + "/ico_arrow_right_gray.png"}
                    style={{ width: "19px", height: "19px" }}
                  />
                  <text style={{ color: "#888" }}>
                    &nbsp;&nbsp;포스트관리&nbsp;&nbsp;
                  </text>
                  <img
                    src={process.env.PUBLIC_URL + "/ico_arrow_right_gray.png"}
                    style={{ Width: "19px", height: "19px" }}
                  />
                  <text>&nbsp;&nbsp;포스트 등록</text>
                </div>
              </HorizontalContainer>
              <div>
                <hr style={{ border: "1px solid #E9EAEE" }} />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "space-between",
                  paddingTop: "10px",
                  paddingBottom: "20px",
                  paddingLeft: "55px",
                  paddingRight: "55px",
                }}
              >
                <div>
                  {category.map((item, index) => (
                    <Button
                      onClick={() => {
                        if (!isEdit) {
                          // isEdit가 false일 때만 작동하게 함
                          setCategoryIndex(item.cateNo);
                        }
                      }}
                      disabled={isEdit} // isEdit가 true일 때 버튼 비활성화
                      style={{
                        borderColor:
                          catergoryIndex === item.cateNo ? "black" : "#C4C4C4",
                        cursor: "pointer",
                      }}
                    >
                      {item.cateName}
                    </Button>
                  ))}
                </div>

                <div>
                  <label>작성타입</label>
                  <Select
                    value={selectedWriteType}
                    onChange={handleSelectWriteType}
                    disabled={isEdit} // isEdit가 true일 때 선택 비활성화
                  >
                    {writeType.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                </div>

                <div>
                  <ColorButton
                    style={{
                      background: "#fff",
                      color: "#4674FE",
                      borderColor: "#4674FE",
                    }}
                    onClick={() => {
                      if (isEdit) {
                        selectedWriteType == 0
                          ? UpdatePost(
                              title,
                              content,
                              catergoryIndex,
                              thumbData,
                              imageData,
                              homeData,
                              relateData,
                              writerid,
                              summary
                            )
                          : selectedWriteType == 1
                          ? UpdatePost_YOUTUBE(
                              title,
                              thumbData,
                              homeData,
                              relateData,
                              content,
                              contentLink,
                              writerid,
                              // source,
                              // sourcelink,
                              catergoryIndex,
                              selectedWriteType
                            )
                          : UpdatePost_Blog(
                              title,
                              thumbData,
                              homeData,
                              relateData,
                              contentLink,
                              catergoryIndex,
                              selectedWriteType
                            );
                      } else {
                        selectedWriteType == 0
                          ? InsertPost(
                              title,
                              content,
                              catergoryIndex,
                              thumbData,
                              imageData,
                              homeData,
                              relateData,
                              writerid,
                              source,
                              sourcelink,
                              summary
                            )
                          : selectedWriteType == 1
                          ? InsertPost_YOUTUBE(
                              title,
                              thumbData,
                              homeData,
                              relateData,
                              content,
                              contentLink,
                              writerid,
                              source,
                              sourcelink,
                              catergoryIndex,
                              selectedWriteType
                            )
                          : InsertPost_BLOG(
                              title,
                              thumbData,
                              homeData,
                              relateData,
                              contentLink,
                              catergoryIndex,
                              selectedWriteType
                            );
                      }
                    }}
                  >
                    <text>등록하기</text>
                  </ColorButton>
                </div>
              </div>
            </HeaderCon>

            {/* { 작성타입 : 기본 } */}
            {selectedWriteType == 0 && (
              <>
                <ThumbCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>썸네일 </text>
                  <br></br>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <br></br>
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex" }}>
                      <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                        <hr style={{ border: "1px solid #E9EAEE" }} />
                      </div>
                      <div style={{ marginRight: "40px" }}>
                        <div style={{}}>
                          <text>포스트 리스트 썸네일</text>
                        </div>
                        <div>
                          <ImageSelector
                            image={thumbimg}
                            setImage={setThumbimg}
                            setData={setThumbData}
                            width="314px"
                            height="114px"
                          />
                          <div style={{ marginTop: "20px" }}>
                            <text>(첨부 크기 : 314 x 114)</text>
                          </div>
                        </div>
                      </div>
                      <div style={{ marginRight: "40px" }}>
                        <div>
                          <text>홈 포스트 리스트 썸네일</text>
                        </div>
                        <div>
                          <ImageSelector
                            image={homeimg}
                            setImage={setHomeimg}
                            setData={setHomeData}
                            width="288px"
                            height="145px"
                          />
                        </div>
                      </div>
                      <div style={{ marginRight: "40px" }}>
                        <div>
                          <text>포스트 본문 연관스토리 썸네일</text>
                        </div>
                        <div>
                          <ImageSelector
                            image={relateimg}
                            setImage={setRelateimg}
                            setData={setRelateData}
                            width="150px"
                            height="188px"
                          />
                          <div style={{ marginTop: "20px" }}>
                            <text>(첨부 크기 : 150 x 188)</text>
                          </div>
                        </div>
                      </div>

                      <div style={{}}>
                        <div>
                          <text>포스트 메인 상단 이미지</text>
                        </div>
                        <div>
                          <ImageSelector
                            image={image}
                            setImage={setImage}
                            setData={setImageData}
                            width="360px"
                            height="280px"
                          />
                          <div style={{ marginTop: "20px" }}>
                            <text>(첨부 크기 : 360 x 280)</text>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ThumbCon>

                <TitleCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>제목</text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <Textinput text={title} setText={setTitle} />
                </TitleCon>

                <WriteCon onPaste={handlePaste}>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>본문</text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <Editor
                    initialValue={content}
                    ref={editorRef}
                    onChange={handleChange}
                    placeholder="본문을 입력해주세요."
                    previewStyle="vertical" // 미리보기 스타일 지정
                    height="300px" // 에디터 창 높이
                    initialEditType="wysiwyg" // 초기 입력모드 설정(디폴트 markdown)
                    toolbarItems={[
                      // 툴바 옵션 설정
                      ["heading", "bold", "italic", "strike"],
                      ["hr", "quote"],
                      ["ul", "ol", "task", "indent", "outdent"],
                      ["table", "image", "link"],
                      ["code", "codeblock"],
                    ]}
                  ></Editor>
                </WriteCon>

                <SourceCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>요약</text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>
                    요약 본문
                  </text>
                  <br />
                  <TextAreainput text={summary} setText={setSummary} />
                </SourceCon>

                <SourceCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>에디터</text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>작성자</text>
                  <br></br>
                  <Select
                    disabled={isEdit}
                    value={selectedWriter}
                    onChange={handleSelect}
                    style={{ border: "1px solid #d3d3d3" }}
                  >
                    {WriterList.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                  <br></br>
                  <br></br>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "35%", marginRight: "5%" }}>
                      <text style={{ fontWeight: 600, fontsize: 16 }}>
                        출처 표시 명
                      </text>
                      <Sourceinput text={source} setText={setSource} />
                    </div>
                    <div style={{ width: "45%" }}>
                      <text style={{ fontWeight: 600, fontsize: 16 }}>
                        출처 클릭시 이동 URL
                      </text>
                      <Sourceinput text={sourcelink} setText={setSourcelink} />
                    </div>
                  </div>
                </SourceCon>
              </>
            )}
            {/* { 작성타입 : 유튜브 } */}
            {selectedWriteType == 1 && (
              <>
                <ThumbCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>썸네일</text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "40px" }}>
                      <div>
                        <text>포스트 리스트 썸네일</text>
                      </div>
                      <div>
                        <ImageSelector
                          image={thumbimg}
                          setImage={setThumbimg}
                          setData={setThumbData}
                          width="314px"
                          height="114px"
                        />
                        <div style={{ marginTop: "20px" }}>
                          <text>(첨부 크기 : 314 x 114)</text>
                        </div>
                      </div>
                    </div>
                    <div style={{ marginRight: "40px" }}>
                      <div style={{}}>
                        <text>홈 포스트 리스트 썸네일</text>
                      </div>
                      <div>
                        <ImageSelector
                          image={homeimg}
                          setImage={setHomeimg}
                          setData={setHomeData}
                          width="288px"
                          height="145px"
                        />
                      </div>
                    </div>
                    <div style={{}}>
                      <div style={{}}>
                        <text>포스트 본문 연관스토리 썸네일</text>
                      </div>
                      <div>
                        <ImageSelector
                          image={relateimg}
                          setImage={setRelateimg}
                          setData={setRelateData}
                          width="150px"
                          height="188px"
                        />
                        <div style={{ marginTop: "20px" }}>
                          <text>(첨부 크기 : 150 x 188)</text>
                        </div>
                      </div>
                    </div>
                  </div>
                </ThumbCon>

                <TitleCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>제목</text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <Textinput text={title} setText={setTitle} />
                </TitleCon>

                <SourceCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>에디터</text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>작성자</text>
                  <br></br>
                  <Select
                    disabled={isEdit}
                    value={selectedWriter}
                    onChange={handleSelect}
                    style={{ border: "1px solid #d3d3d3" }}
                  >
                    {WriterList.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                  <br></br>
                  <br></br>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "35%", marginRight: "5%" }}>
                      <text style={{ fontWeight: 600, fontsize: 16 }}>
                        출처 표시 명
                      </text>
                      <Sourceinput text={source} setText={setSource} />
                    </div>
                    <div style={{ width: "45%" }}>
                      <text style={{ fontWeight: 600, fontsize: 16 }}>
                        출처 클릭시 이동 URL
                      </text>
                      <Sourceinput text={sourcelink} setText={setSourcelink} />
                    </div>
                  </div>
                </SourceCon>

                <TitleCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>
                    유튜브 링크
                  </text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <Sourceinput text={contentLink} setText={setContentLink} />
                </TitleCon>
                <WriteCon onPaste={handlePaste}>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>본문</text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <Editor
                    initialValue={content}
                    ref={editorRef}
                    onChange={handleChange}
                    placeholder="본문을 입력해주세요."
                    previewStyle="vertical" // 미리보기 스타일 지정
                    height="300px" // 에디터 창 높이
                    initialEditType="wysiwyg" // 초기 입력모드 설정(디폴트 markdown)
                    toolbarItems={[
                      // 툴바 옵션 설정
                      ["heading", "bold", "italic", "strike"],
                      ["hr", "quote"],
                      ["ul", "ol", "task", "indent", "outdent"],
                      ["table", "image", "link"],
                      ["code", "codeblock"],
                    ]}
                  ></Editor>
                </WriteCon>
              </>
            )}
            {/* { 작성타입 : 블로그 } */}
            {selectedWriteType == 2 && (
              <>
                <ThumbCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>썸네일</text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "40px" }}>
                      <div>
                        <text>포스트 리스트 썸네일</text>
                      </div>
                      <div>
                        <div>
                          <ImageSelector
                            image={thumbimg}
                            setImage={setThumbimg}
                            setData={setThumbData}
                            width="314px"
                            height="114px"
                          />
                          <div style={{ marginTop: "20px" }}>
                            <text>(첨부 크기 : 314 x 114)</text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ marginRight: "40px" }}>
                      <div>
                        <text>홈 포스트 리스트 썸네일</text>
                      </div>
                      <div>
                        <ImageSelector
                          image={homeimg}
                          setImage={setHomeimg}
                          setData={setHomeData}
                          width="288px"
                          height="145px"
                        />
                      </div>
                    </div>
                    <div>
                      <div>
                        <text>포스트 본문 연관스토리 썸네일</text>
                      </div>
                      <div>
                        <ImageSelector
                          image={relateimg}
                          setImage={setRelateimg}
                          setData={setRelateData}
                          width="150px"
                          height="188px"
                        />
                        <div style={{ marginTop: "20px" }}>
                          <text>(첨부 크기 : 150 x 188)</text>
                        </div>
                      </div>
                    </div>
                  </div>
                </ThumbCon>

                <TitleCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>제목</text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <Textinput text={title} setText={setTitle} />
                </TitleCon>

                <TitleCon>
                  <text style={{ fontWeight: 600, fontsize: 16 }}>
                    블로그 링크
                  </text>
                  <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                    <hr style={{ border: "1px solid #E9EAEE" }} />
                  </div>
                  <Sourceinput text={contentLink} setText={setContentLink} />
                </TitleCon>
              </>
            )}
          </VarticalContainer>
        </>
      }
    ></Layout>
  );
}
const VarticalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 90vh;
`;
const HorizontalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 30px 60px 30px 60px;
`;
const HeaderCon = styled.div`
  flex: 0.1;
  background-color: #fff;
  box-sizing: border-box;
`;
const ThumbCon = styled.div`
  flex: 1;
  // display: flex;
  background-color: #fff;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;
const DetailCon = styled.div`
  flex: 0.2;
  background-color: #fff;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;
const TitleCon = styled.div`
  flex: 0.14;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;
const SourceCon = styled.div`
  flex: 0.14;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;
const Writer = styled.div`
  flex: 0.14;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
  display: flex; // 추가
  justify-content: space-between; // 추가
  select {
    font-size: 16px;
    padding: 4px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

const Select = styled.select`
  margin: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #eee; /* 아래쪽 테두리만 적용 */
  padding: 0.5rem;
  height: 34px;
  width: 360px;
  cursor: pointer;
`;

const WriteCon = styled.div`
  flex: 0.36;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;

const ColorButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.0001);
  border: 1px solid #4674fe;
  border-radius: 20px;
  cursor: pointer;
  height: 34px;
  width: 101px;
  border-radius: 20px;
  margin-top: 10px;
`;
const Button = styled.button`
  background: #ffffff;
  border: 2px solid #000000;
  border-radius: 4px;
  width: 174px;
  height: 54px;
  margin-right: 6.76px;
`;

const MainTitle = styled.text`
  font-family: "NanumBarunGothicOTF";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
  display: flex;
  align-items: center;
`;
