import React, { useEffect, useState } from "react";

const DateInput = ({ date, setDate, disabled }) => {
  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  return (
    <div>
      <input
        type="date"
        value={date}
        onChange={handleDateChange}
        disabled={disabled}
      />
    </div>
  );
};

export default DateInput;
