import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import styled, { css, keyframes } from "styled-components";
import axios from "axios";
import MultiImageSelector from "../components/MultiImageSelector";
import Textinput from "../components/Textinput";
import DateInput from "../components/Dateinput";
import Sourceinput from "../components/Sourceinput";
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store";
import userSlice from "../slice/user";
import ogs from "open-graph-scraper"; // Import the open-graph-scraper library

export default function Post_Write() {
  const location = useLocation();
  const existTitle = location.state?.title;
  const existContent = location.state?.content;
  const existMainimg =
    location.state?.mainimg &&
    "https://media.dplanit.co.kr/" + location.state?.mainimg;
  const existThumbimg =
    location.state?.thumbimg &&
    "https://media.dplanit.co.kr" + location.state?.thumbimg;
  const existType = location.state?.type;
  const existDate = location.state?.date;
  const subject = location.state?.subject;
  const subjectcode = location.state?.subjectcode;
  const writetype = location.state?.writeType;
  const postno = location.state?._id;
  const writerid = location.state?.writerid;
  const existsource = location.state?.source;
  const link = location.state?.link;
  const existSummary = location.state?.summary;
  const isEdit = location.state?.isEdit;
  const storylink = location.state?.contentLink;
  const [category, setCategory] = useState([]);
  const [categoryNo, setCategoryNo] = useState([]);
  const [catergoryIndex, setCategoryIndex] = useState(existType || 0);
  const [title, setTitle] = useState(existTitle || "");
  // const [link, setLink] = useState(existTitle || "");
  const [source, setSource] = useState(existsource || "");
  const [summary, setSummary] = useState(existSummary || "");
  const [sourcelink, setSourcelink] = useState(link || "");
  const [content, setContent] = useState(existContent || "");
  const [WriterList, setWriterList] = useState([]);
  const [selectedWriter, setSelectedWriter] = useState(writerid || 1);

  const [image, setImage] = useState([null, null, null, null, null]);
  const [imageArray, setImageArray] = useState([null, null, null, null, null]);
  // <이미지 배열 담을것>
  const [imageData, setImageData] = useState(
    existMainimg || [
      new FormData(),
      new FormData(),
      new FormData(),
      new FormData(),
      new FormData(),
    ]
  );

  const [thumbimg, setThumbimg] = useState(existThumbimg || null);
  const [thumbData, setThumbData] = useState(existThumbimg || null);
  const [loading, setLoading] = useState(false);
  const [writeType, setWriteType] = useState([
    { value: 0, label: "info" },
    { value: 1, label: "ad" },
  ]);
  const [option, setOption] = useState([
    { no: 0, name: "디플톡" },
    { no: 1, name: "동네톡" },
  ]);
  const [selectedWriteType, setSelectedWriteType] = useState(writetype || 0);
  const [contentLink, setContentLink] = useState(storylink || "");
  const [selectedInsertOption, setSelectedInsertOption] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState("1100000000");
  const [selectedSublocation, setSelectedSublocation] = useState("" || {});
  const [local, setLocal] = useState([]);
  const [sublocation, setSublocatation] = useState([]);
  const [selectedInsertCate, setSelectedInsertCate] = useState(1);
  const [cate, setCate] = useState([]);
  const [date, setDate] = useState(existDate || "");
  const dispatch = useAppDispatch();
  const editorRef = useRef(null);
  const navigation = useNavigate();
  useEffect(() => {
    handleLocation();
    handleSubLocation();
    getCateList();
  }, [loading, selectedInsertOption, selectedSublocation]);
  useEffect(() => {
    handleLocation();
    handleSubLocation(selectedSublocation);
  }, [selectedLocation]);
  useEffect(() => {
    getCateList();
  }, []);

  // useEffect(() => {
  //   for (let i = 0; i < image.length; i++) {
  //     setImageData(new File(image[i]));
  //   }
  // }, [image]);

  const handleSelect = (e) => {
    setWriteType(e.target.value);
  };
  const handleSelectInsertOption = (e, type) => {
    setSelectedInsertOption(e.target.value);
  };
  const handleImageUpload = (event) => {
    setImage(Array.from(event.target.files));
  };
  console.log("selectedWriteType : ", selectedWriteType);
  console.log("postno : ", postno);
  const handleSelectInsertCate = (e, type) => {
    setSelectedInsertCate(e.target.value);
  };
  const getCateList = async () => {
    try {
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/talk/category`,
          { type: selectedInsertOption },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          console.log("catecatecatecatecatecatecatecate", res.data.data);
          setCate(res.data.data);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.accesstoken;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/talk/category`,
          { type: selectedInsertOption },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setCate(res.data.data);
        });
    }
  };
  console.log("selectedInsertCate  : ", selectedInsertCate);

  const handlePaste = async (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");

    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

    if (urlRegex.test(pastedData)) {
      event.preventDefault();

      try {
        setLoading(true);
        const ogData = await makeNewLink(pastedData);
        const ogHTML = ogData.data.data.data;

        const updatedContent =
          content.replace(new RegExp(pastedData, "g"), "") + ogHTML;
        editorRef.current.getInstance().setMarkdown(updatedContent);

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch OG data:", error);
      }
    }
  };

  console.log("image : ", image);
  const Cate = async () => {
    try {
      await axios
        .get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/story/category`,
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setCategory(res.data.data);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      if (response.data.popdata.poptext) {
        swal(response.data.popdata.poptext, { icon: "error" });
        return;
      }
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/story/category`,
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setCategory(res.data.data.cateName);
          setCategoryNo(res.data.data.cateNo);
        });
    }
  };
  const makeNewLink = async (url) => {
    const response = await axios.post(
      `${
        process.env.NODE_ENV == "development"
          ? "http://localhost:2005"
          : "https://dplanit.dplanit.co.kr"
      }/admin/post/newlink`,
      { url: url },
      {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
      }
    );
    return response;
  };
  const handleChange = () => {
    setContent(editorRef.current?.getInstance().getHTML());
  };
  // const name = !selectedSublocation ? "" : JSON.parse(selectedSublocation).name;
  let isoDate = new Date(date);
  console.log("content : ", content);
  console.log("title : ", title);
  console.log("thumbimg : ", imageData);
  console.log("selectedWriter : ", selectedWriteType);
  console.log("subjectcode : ", selectedInsertCate);
  console.log("image : ", image);
  console.log("imageArray : ", imageArray);
  console.log(
    "selectedLocation.name : ",
    JSON.stringify(selectedSublocation) === "{}"
      ? ""
      : JSON.parse(selectedSublocation).name
  );
  console.log(
    "selectedLocation.code : ",
    JSON.stringify(selectedSublocation) === "{}"
      ? ""
      : JSON.parse(selectedSublocation)
  );
  // console.log("date : ", isoDate.toISOString());
  console.log("selectedSublocation : ", selectedSublocation);

  const InsertPost = async (
    title,
    content,
    image,
    type,
    subjectcode,
    // name,
    location,
    locationnamem
  ) => {
    if (!title) {
      swal("제목을 입력해주세요", { icon: "error" });
    }
    if (!content) {
      swal("내용을 입력해주세요", { icon: "error" });
    }
    if (!image) {
      swal("썸네일 이미지를 입력해주세요", { icon: "error" });
    }
    if (!selectedWriter) {
      swal("작성자를 입력해주세요", { icon: "error" });
    }
    if (!type) {
      swal("타입 입력해주세요", { icon: "error" });
    }
    if (!selectedInsertCate) {
      swal("타입 입력해주세요", { icon: "error" });
    }
    if (!selectedInsertCate) {
      swal("카테고리 입력해주세요", { icon: "error" });
    }
    if (!selectedSublocation) {
      swal("code 입력해주세요", { icon: "error" });
    }
    if (!date) {
      swal("날짜 입력해주세요", { icon: "error" });
    }
    // const name = selectedSublocation
    //   ? ""
    //   : JSON.parse(selectedSublocation).name;
    // const code = selectedSublocation
    //   ? ""
    //   : JSON.parse(selectedSublocation).code;
    const name =
      JSON.stringify(selectedSublocation) === "{}"
        ? ""
        : JSON.parse(selectedSublocation).name;

    const code =
      JSON.stringify(selectedSublocation) === "{}"
        ? ""
        : JSON.parse(selectedSublocation).code;
    let isoDate = new Date(date);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("text", content);
    image.forEach((image, index) => {
      formData.append(`thumbimg`, image);
    });
    formData.append("type", type);
    formData.append("subjectcode", selectedInsertCate);
    formData.append("localcode", code);
    formData.append("location", name);
    formData.append("date", isoDate.toISOString());
    try {
      await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/talk/adinsert`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/talkad");
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      const response_2 = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/talk/adinsert`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      if (response_2.data.popdata.poptext) {
        swal(response_2.data.popdata.poptext, {
          icon: "error",
        });
      }
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/talkad");
    }
  };
  const InsertPost_YOUTUBE = async (
    title,
    thumpath,
    content,
    contentLink,
    type,
    postType
  ) => {
    if (!title) {
      swal("제목을 입력해주세요", { icon: "error" });
    }
    if (!content) {
      swal("내용을 입력해주세요", { icon: "error" });
    }
    if (!contentLink) {
      swal("컨텐츠링크를 입력해주세요", { icon: "error" });
    }
    if (!thumpath) {
      swal("썸네일 이미지를 입력해주세요", { icon: "error" });
    }
    if (!type) {
      swal("카테고리가 존재하지 않습니다.", { icon: "error" });
    }
    if (!postType) {
      swal("게시글 타입이 존재하지 않습니다.", { icon: "error" });
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("text", content);
    formData.append("thumbimg", thumpath);
    formData.append("type", type);
    formData.append("postType", postType);
    try {
      await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/story/insert`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("Authorization", newAct);
      const response_2 = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/story/insert`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      if (response_2.data.popdata.poptext) {
        swal(response_2.data.popdata.poptext, {
          icon: "error",
        });
      }
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    }
  };
  const InsertPost_BLOG = async (
    title,
    thumpath,
    contentLink,
    type,
    postType
  ) => {
    if (!title) {
      swal("제목을 입력해주세요", { icon: "error" });
    }
    if (!thumpath) {
      swal("썸네일 이미지를 입력해주세요", { icon: "error" });
    }
    if (!contentLink) {
      swal("컨텐츠링크를 입력해주세요", { icon: "error" });
    }
    if (!type) {
      swal("카테고리가 존재하지 않습니다.", { icon: "error" });
    }
    if (!postType) {
      swal("게시글 타입이 존재하지 않습니다.", { icon: "error" });
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("contentLink", contentLink);
    formData.append("thumbimg", thumpath);
    formData.append("type", type);
    formData.append("postType", postType);
    try {
      await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/story/insert`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("Authorization", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      const response_2 = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/story/insert`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      if (response_2.data.popdata.poptext) {
        swal(response_2.data.popdata.poptext, {
          icon: "error",
        });
      }
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    }
  };

  const UpdatePost = async (
    title,
    content,
    selectedWriteType,
    subjectcode,
    postno,
    date
  ) => {
    console.log("datedate : ", date);
    if (!title) {
      swal("제목을 입력해주세요", { icon: "error" });
    }
    if (!content) {
      swal("내용을 입력해주세요", { icon: "error" });
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("text", content);
    formData.append("type", selectedWriteType);
    formData.append("subjectcode", subjectcode);
    formData.append("contentno", postno);
    formData.append("date", date);
    try {
      await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/talk/adupdate`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      const response_2 = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/talk/adupdate`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      if (response_2.data.popdata.poptext) {
        swal(response_2.data.popdata.poptext, {
          icon: "error",
        });
      }
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    }
  };
  const handleLocation = async (newMemo, no) => {
    try {
      await axios
        .get(
          `${"https://grpc-proxy-server-mkvo6j4wsq-du.a.run.app/v1/regcodes?regcode_pattern=**00000000"}`,
          { page: 1, sbjcode: 1 },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          console.log(
            "LocalLocalLocalLocalLocalLocalLocalLocalLocal",
            res.data.regcodes
          );
          setLocal(res.data.regcodes);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .get(
          `${"https://grpc-proxy-server-mkvo6j4wsq-du.a.run.app/v1/regcodes?regcode_pattern=**00000000"}`,
          { page: 1, sbjcode: 1 },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          console.log("LocalLocalLocalLocalLocal", res);
          setLocal(res.data.regcodes);
        });
    }
  };
  console.log(selectedLocation);

  let sub = selectedLocation.slice(0, 2);
  console.log(sub);
  const handleSubLocation = async (selectedLocation) => {
    try {
      await axios
        .get(
          `${`https://grpc-proxy-server-mkvo6j4wsq-du.a.run.app/v1/regcodes?regcode_pattern=${sub}***00000`}`,
          { page: 1, sbjcode: 1 },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          console.log(
            "handleSubLocationhandleSubLocationhandleSubLocationhandleSubLocationhandleSubLocation",
            res.data.regcodes
          );
          var regdatas = res.data.regcodes;
          var temparr = new Array();
          for (var i = 0; i < regdatas.length; i++) {
            if (regdatas[i].code.substring(2, regdatas.length) == "00000000") {
              continue;
            }
            if (regdatas[i].code[4] != "0") {
              temparr.push(regdatas[i]);
              continue;
            }
            var isfind = false;
            for (var j = 0; j < regdatas.length; j++) {
              if (j == i) continue;

              if (
                regdatas[i].code.substring(0, 4) ==
                regdatas[j].code.substring(0, 4)
              ) {
                isfind = true;
                break;
              }
            }
            if (!isfind) temparr.push(regdatas[i]);
          }
          console.log(temparr);
          setSublocatation(temparr);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .get(
          `${`https://grpc-proxy-server-mkvo6j4wsq-du.a.run.app/v1/regcodes?regcode_pattern=${sub}***00000`}`,
          { page: 1, sbjcode: 1 },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          var regdatas = res.data.regcodes;
          var temparr = new Array();
          for (var i = 0; i < regdatas.length; i++) {
            if (regdatas[i].code.substring(2, regdatas.length) == "00000000") {
              continue;
            }
            if (regdatas[i].code[4] != "0") {
              temparr.push(regdatas[i]);
              continue;
            }
            var isfind = false;
            for (var j = 0; j < regdatas.length; j++) {
              if (j == i) continue;

              if (
                regdatas[i].code.substring(0, 4) ==
                regdatas[j].code.substring(0, 4)
              ) {
                isfind = true;
                break;
              }
            }
            if (!isfind) temparr.push(regdatas[i]);
          }
          console.log(temparr);
        });
    }
  };
  console.log(" selectedSublocation : ", selectedSublocation);
  console.log(" selectedInsertCate : ", selectedInsertCate);
  const handleSelectWriteType = (e) => {
    setSelectedWriteType(e.target.value);
  };
  const handleSelectLocate = (e, type) => {
    setSelectedLocation(e.target.value);
  };
  const handleSelectSubLocate = (e, type) => {
    setSelectedSublocation(e.target.value);
  };

  console.log("imageData : ", imageData);
  return (
    <Layout
      children={
        <>
          <VarticalContainer>
            <HeaderCon>
              <HorizontalContainer>
                <MainTitle>포스트 등록</MainTitle>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <text style={{ color: "#888" }}>홈 &nbsp;&nbsp;</text>
                  <img
                    src={process.env.PUBLIC_URL + "/ico_arrow_right_gray.png"}
                    style={{ width: "19px", height: "19px" }}
                  />
                  <text style={{ color: "#888" }}>
                    &nbsp;&nbsp;TalkAd&nbsp;&nbsp;
                  </text>
                  <img
                    src={process.env.PUBLIC_URL + "/ico_arrow_right_gray.png"}
                    style={{ Width: "19px", height: "19px" }}
                  />
                  <text>&nbsp;&nbsp;TalkAd 등록</text>
                </div>
              </HorizontalContainer>
              <div>
                <hr style={{ border: "1px solid #E9EAEE" }} />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "space-between",
                  paddingTop: "10px",
                  paddingBottom: "20px",
                  paddingLeft: "55px",
                  paddingRight: "55px",
                }}
              >
                <div>
                  <label>톡 선택</label>
                  <Select
                    value={selectedInsertOption}
                    onChange={handleSelectInsertOption}
                    disabled={isEdit}
                  >
                    {option.map((item) => (
                      <option key={item.id} value={item.no}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </div>
                {selectedInsertOption == 1 && (
                  <>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <label>시</label>
                      <Select
                        value={selectedLocation}
                        onChange={handleSelectLocate}
                        disabled={isEdit}
                      >
                        {local.map((item) => (
                          <option key={item.name} value={item.code}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </div>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <label>군/구</label>
                      <Select
                        value={selectedSublocation}
                        onChange={handleSelectSubLocate}
                        disabled={isEdit}
                      >
                        {sublocation.map((item, index) => (
                          <option key={item.name} value={JSON.stringify(item)}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </>
                )}
                <div>
                  <label>작성타입</label>
                  <Select
                    value={selectedWriteType}
                    onChange={handleSelectWriteType}
                    disabled={isEdit}
                  >
                    {writeType.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                </div>
                <div>
                  {!isEdit && (
                    <ColorButton
                      style={{
                        background: "#fff",
                        color: "#4674FE",
                        borderColor: "#4674FE",
                      }}
                      onClick={() => {
                        let type = selectedWriteType === 1 ? "ad" : "info";
                        if (isEdit) {
                          UpdatePost(
                            title,
                            content,
                            catergoryIndex,
                            type,
                            postno,
                            date
                          );
                        } else {
                          let { code, name } = selectedSublocation;
                          InsertPost(
                            title,
                            content,
                            imageData,
                            type,
                            selectedInsertCate,
                            // selectedLocation,
                            // sublocation,
                            name,
                            date
                          );
                        }
                      }}
                    >
                      <text>등록하기</text>
                    </ColorButton>
                  )}
                </div>
              </div>
            </HeaderCon>

            <>
              <ThumbCon>
                <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                  <hr style={{ border: "1px solid #E9EAEE" }} />
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "40px" }}>
                    <text>메인 이미지</text>
                  </div>
                  {image.map((images, index) => (
                    <MultiImageSelector
                      key={index}
                      image={images}
                      setImage={(newFile) => {
                        let newImages = [...image];
                        newImages[index] = newFile;
                        setImage(newImages);
                        new FormData();
                      }}
                      setData={setImageData}
                      width="133px"
                      height="145px"
                      isEdit={isEdit}
                      disabled={isEdit}
                    />
                  ))}
                  {/* <div>
      <input type="file" multiple ref={inputRef} />
      <button onClick={uploadImages}>Upload Images</button>
    </div> */}

                  {/* {image.length < 5 && (
                    <ImageSelector
                      image={imageData}
                      setImage={setImage}
                      setData={setImageData}
                      width="133px"
                      height="145px"
                      isEdit={isEdit}
                    />
                  )} */}
                  {/* <ImageSelector
                      image={image}
                      setImage={setImage}
                      setData={setImageData}
                      width="133px"
                      height="145px"
                      isEdit={isEdit}
                    />
                    <ImageSelector
                      image={image}
                      setImage={setImage}
                      setData={setImageData}
                      width="133px"
                      height="145px"
                      isEdit={isEdit}
                    />
                    <ImageSelector
                      image={image}
                      setImage={setImage}
                      setData={setImageData}
                      width="133px"
                      height="145px"
                      isEdit={isEdit}
                    />
                    <ImageSelector
                      image={image}
                      setImage={setImage}
                      setData={setImageData}
                      width="133px"
                      height="145px"
                      isEdit={isEdit}
                    /> */}
                  <div style={{ marginTop: "20px" }}>
                    <text>최대 5개</text>
                  </div>
                </div>
              </ThumbCon>
              <TitleCon>
                <div>
                  <label>카테고리</label>
                  <Select
                    value={selectedInsertCate}
                    onChange={handleSelectInsertCate}
                    disabled={isEdit}
                  >
                    {cate.map(
                      (item, index) =>
                        index !== 0 && (
                          <option key={item.id} value={item.code}>
                            {item.subject}
                          </option>
                        )
                    )}
                  </Select>
                </div>
              </TitleCon>
              <TitleCon>
                <text style={{ fontWeight: 600, fontsize: 16 }}>제목</text>
                <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                  <hr style={{ border: "1px solid #E9EAEE" }} />
                </div>
                <Textinput text={title} setText={setTitle} disabled={isEdit} />
              </TitleCon>
              <TitleCon disabled={isEdit}>
                <text style={{ fontWeight: 600, fontSize: 16 }}>만료일</text>
                <div style={{ marginBottom: "40px", marginTop: "30px" }}></div>
                <DateInput date={date} setDate={setDate} disabled={isEdit} />
              </TitleCon>

              <WriteCon>
                <text style={{ fontWeight: 600, fontsize: 18 }}>내용</text>
                <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                  <hr style={{ border: "1px solid #E9EAEE" }} />
                </div>
                <textarea
                  style={{
                    width: "100%",
                    height: "400px",
                    resize: "none",
                    marginTop: 10,
                    overflow: "scroll",
                  }}
                  placeholder="내용"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  disabled={isEdit}
                />
              </WriteCon>
            </>
          </VarticalContainer>
        </>
      }
    ></Layout>
  );
}
const VarticalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 90vh;
`;
const HorizontalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 30px 60px 30px 60px;
`;
const HeaderCon = styled.div`
  flex: 0.1;
  background-color: #fff;
  box-sizing: border-box;
`;
const ThumbCon = styled.div`
  flex: 1;
  display: flex;
  background-color: #fff;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;
const DetailCon = styled.div`
  flex: 0.2;
  background-color: #fff;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;
const TitleCon = styled.div`
  flex: 0.14;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;
const SourceCon = styled.div`
  flex: 0.14;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;
const Writer = styled.div`
  flex: 0.14;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
  display: flex; // 추가
  justify-content: space-between; // 추가
  select {
    font-size: 16px;
    padding: 4px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

const Select = styled.select`
  margin: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #eee; /* 아래쪽 테두리만 적용 */
  padding: 0.5rem;
  height: 34px;
  width: 360px;
  cursor: pointer;
`;

const WriteCon = styled.div`
  flex: 0.36;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;

const ColorButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.0001);
  border: 1px solid #4674fe;
  border-radius: 20px;
  cursor: pointer;
  height: 34px;
  width: 101px;
  border-radius: 20px;
  margin-top: 10px;
`;
const Button = styled.button`
  background: #ffffff;
  border: 2px solid #000000;
  border-radius: 4px;
  width: 174px;
  height: 54px;
  margin-right: 6.76px;
`;

const MainTitle = styled.text`
  font-family: "NanumBarunGothicOTF";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
  display: flex;
  align-items: center;
`;
