import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import styled, { css, keyframes } from "styled-components";
import axios from "axios";
import Textinput from "../components/Textinput";
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import CustomButton from "../components/CustomButton";
import CmtContainer from "../components/Talk/CmtContainer";
import Pagenation from "../components/Pagenation";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store";
import userSlice from "../slice/user";
import { useLocation, useNavigate } from "react-router";
export default function BannerDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const existTitle = location.state?.title;
  const existContent = location.state?.content;
  const isEdit = location.state?.isEdit;
  const date = location.state?.date;
  //   const contentno = location.state?.id;
  const nickname = location.state?.nickname;
  const subject = location.state?.subject;
  const postno = location.state?.postno;
  // const image = location.state?.image;
  console.log("noticeno", location);
  const ITEMSPERPAGE = 20;
  const [title, setTitle] = useState(existTitle || "");
  const [content, setContent] = useState(existContent || "");
  const [cmtList, setCmtList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const editorRef = React.createRef();
  const dispatch = useAppDispatch();
  const accesstoken = useSelector((state) => state.user.accesstoken);
  useEffect(() => {});

  const handleChange = () => {
    const newContent = editorRef.current?.getInstance().getMarkdown();
    setContent(newContent);
  };
  console.log("postno : ", postno);
  // console.log("image : ", image);
  const getCmtList = async () => {
    console.log("getCmtList");

    try {
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/banner/cmnt`,
          { bannerno: postno },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )

        .then((res) => {
          console.log("cmtcmtcmtcmtcmtcmtcmtcmtcmt", res.data.data.datas);
          setCmtList(res.data.data.datas);
          setCount(res.data.data.cnt);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2005"
            : "https://dplanit.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.Authorization;
      localStorage.setItem("accessToken", newAct);
      dispatch(
        userSlice.actions.updateAccessToken({
          Authorization: localStorage.getItem("accessToken"),
        })
      );
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2005"
              : "https://dplanit.dplanit.co.kr"
          }/admin/banner/cmnt`,
          { bannerno: postno },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )

        .then((res) => {
          setCmtList(res.data.data.datas);
          setCount(res.data.data.cnt[0].cnt);
        });
    }
  };

  useEffect(() => {
    console.log("useEffect111");
    getCmtList();
  }, []);

  return (
    <Layout
      children={
        <>
          <VarticalContainer>
            <HeaderCon>
              <HorizontalContainer>
                <text
                  style={{
                    fontSize: "30px",
                    fontWeight: 600,
                  }}
                >
                  TalkDetail
                </text>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <text
                    style={{ color: "#888", fontSize: "14px", fontWeight: 400 }}
                  >
                    홈 &nbsp;&nbsp;
                  </text>

                  <text
                    style={{ color: "#888", fontSize: "14px", fontWeight: 400 }}
                  >
                    &nbsp;&nbsp;Talk&nbsp;&nbsp;
                  </text>
                  <img
                    src={process.env.PUBLIC_URL + "/ico_arrow_right_gray.png"}
                    style={{ Width: "19px", height: "19px" }}
                  />
                  <text style={{ fontSize: "14px", fontWeight: 400 }}>
                    &nbsp;&nbsp;TalkDetail
                  </text>
                </div>
              </HorizontalContainer>
            </HeaderCon>
            <TitleCon>
              {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
              {/* <text style={{ fontWeight: 600, fontsize: 18 }}>
                  제목 : {title}
                </text> */}
              {/* <text> 작성자 : {nickname}</text>
                <text> 작성 시간 : {date}</text> */}
              {/* </div> */}

              {/* <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                <hr style={{ border: "1px solid #E9EAEE" }} />
              </div> */}

              <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                <hr style={{ border: "1px solid #E9EAEE" }} />
              </div>
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <th>번호</th>
                      <th>닉네임</th>
                      <th>전화번호</th>
                      <th>댓글</th>
                      <th>등록일</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cmtList &&
                      cmtList.map((item, idx) => {
                        const urlPattern =
                          /((http|https):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;
                        const urls = item.COMMENT.match(urlPattern);
                        let commentText = item.COMMENT.replace(
                          urlPattern,
                          ""
                        ).trim();
                        return (
                          <tr>
                            <td>{idx + 1}</td>
                            <td>{item.nickname}</td>
                            <td>{item.phone}</td>
                            <td>
                              {urls ? (
                                <>
                                  {urls.map((url, index) => (
                                    <>
                                      {commentText}
                                      &nbsp;
                                      <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {urls}
                                      </a>
                                    </>
                                  ))}
                                </>
                              ) : (
                                commentText
                              )}
                            </td>
                            <td>{item.WRITE_TIME}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </TableContainer>
            </TitleCon>
          </VarticalContainer>
        </>
      }
    ></Layout>
  );
}
const VarticalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const HorizontalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 30px 60px 30px 60px;
`;
const HeaderCon = styled.div`
  flex: 0.1;
  display: flex;
  background-color: #fff;
  box-sizing: border-box;
`;

const TitleCon = styled.div`
  flex: 0.14;
  background-color: #fff;
  margin-top: 10px;
  padding: 30px 60px 30px 60px;
  box-sizing: border-box;
`;
const WriteCon = styled.div`
  flex: 0.36;
  background-color: #fff;
  margin-top: 10px;
  padding: 30px 60px 30px 60px;
  box-sizing: border-box;
`;

const MainTitle = styled.text`
  font-family: "NanumBarunGothicOTF";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
  display: flex;
  align-items: center;
`;
const TableContainer = styled.div`
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto 50px;
  grid-gap: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    border: 1px solid #e9eaee;
    padding: 8px;
    text-align: center;
    height: 28px;
  }

  th {
    background-color: #f2f2f2;
    height: 28px;
    font-weight: 400;
  }
  grid-column: 1 / span 6;
`;
